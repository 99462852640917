/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable object-shorthand */
/* eslint-disable import/no-extraneous-dependencies */
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { TextField } from "@mui/material";
import {
  LazyLoadImage,
  ScrollPosition,
  trackWindowScroll,
} from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import useTranslation from "../../hooks/Translation";
import ChangePassword from "./ChangePassword";
import {
  useLazyGetProfileQuery,
  usePostProfileMutation,
  usePostProfileOtpMutation,
} from "../../service/Auth";
import useAuth from "../../hooks/UseAuth";
import { generateSEKAndHash } from "../../utils/crypto";
import { UploadMedia } from "../../utils/uploadMedia";
import { User } from "../../types/User";
import { setCredentials } from "../../reducers/authSlice";
import { useAppDispatch } from "../../hooks/Store";
import { errorToast, successToast } from "../../helpers/ShowToast";
import { STORAGE_KEYS, getFromStorage } from "../../helpers/Storage";
import { isString } from "../../utils/validation";
import { Loader } from "../../components";

interface ScreenType {
  ChangePass: boolean;
  setChangePass: Dispatch<SetStateAction<boolean>>;
  scrollPosition: ScrollPosition;
}

const EditProfile = ({
  ChangePass,
  setChangePass,
  scrollPosition,
}: ScreenType) => {
  const user = useAuth();
  const navigate = useNavigate();
  const [GetProfile, GetProfileData] = useLazyGetProfileQuery();
  const deviceId = navigator.userAgent;
  const [PostProfileMutation, PostProfileMutationData] =
    usePostProfileMutation();
  const [PostProfileOtpMutation, { isLoading }] = usePostProfileOtpMutation();
  const [image, setImage] = useState<string | null>(null);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const translation = useTranslation() as any;
  // const [countryName, setCountryName] = useState("in");
  const [phoneCode, setPhoneCode] = useState("+971");
  const [userData, setUserData] = useState<User>();
  const [phoneData, setPhoneData] = useState("");

  const dispatch = useAppDispatch();
  const token = getFromStorage(STORAGE_KEYS.token);

  // formik
  const fetchData = async () => {
    try {
      const response = await GetProfile({}).unwrap();
      if (response?.statusCode === 200) {
        setPhoneData(response?.data?.phone);
        setUserData(response?.data);
        dispatch(
          setCredentials({
            token: JSON.parse(`${token}`),
            user: response?.data || null,
          })
        );
      } else if (response?.statusCode === 503) {
        errorToast(translation.toastMessages.service_unavailable);
      }
    } catch (error: any) {
      if (error?.data?.message) {
        errorToast(error?.data?.message || "");
      } else {
        errorToast(translation.toastMessages.no_internet);
      }
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      userName: userData?.userName || "",
      firstName: userData?.firstName || "",
      lastName: userData?.lastName || "",
      email: userData?.email || "",
      phone: userData?.phone || "",
    },
    validationSchema: Yup.object({
      userName: Yup.string()
        .required(translation.validations.user_required)
        .label("Username")
        .min(2, translation.validations.min_username)
        .max(15)
        .matches(/^[a-zA-Z0-9]+$/, "Username cannot contain underscores"),
      firstName: Yup.string()
        .required(translation.validations.firstname_req)
        .label("FirstName")
        .min(2, translation.validations.min_firstname)
        .max(15),
      lastName: Yup.string()
        .label("LastName")
        .min(2, translation.validations.min_lastname)
        .max(15),
      email: Yup.string()
        .required(translation.validations.email_required)
        .label("Email")
        .matches(
          // eslint-disable-next-line no-useless-escape
          /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i,
          translation.validations.valid_email
        ),
      phone: Yup.string()
        .required(translation.validations.phone_required)
        .min(8, translation.validations.correct_phone)
        .max(14)
        .label("Phone number"),
    }),

    onSubmit: async () => {
      formik.setSubmitting(true);

      const data = {
        userName: formik.values.userName || "",
        firstName: formik.values.firstName || "",
        lastName: formik.values.lastName,
        email: formik.values.email,
        image: image || "",
        countryCode: (phoneCode.includes("+") ? "" : "+") + phoneCode,
        phone: formik.values.phone,
        appkey: new Date().toISOString(),
        deviceId: deviceId,
      };
      const body = generateSEKAndHash(data);
      const newNumber = formik.values?.phone !== userData?.phone;
      if (body) {
        // if (newNumber) {
        //   errorToast(translation.toastMessages.verify_new_phone);
        // } else {
        try {
          const response = await PostProfileMutation(body).unwrap();

          if (response?.statusCode === 200) {
            fetchData();
            successToast(response?.message || "");
          } else if (response?.statusCode === 503) {
            errorToast(translation.toastMessages.service_unavailable);
          }
        } catch (error) {
          // errorToast(error?.data?.message || "");
        }
      }
      // }

      setTimeout(() => {
        formik.setSubmitting(false);
      }, 3000);
    },
  });

  const handleNumberChange = async () => {
    const data = {
      countryCode: (phoneCode.includes("+") ? "" : "+") + phoneCode,
      phone: formik.values.phone,
      deviceId: deviceId,
      appkey: new Date().toISOString(),
    };
    const body = generateSEKAndHash(data);
    if (body) {
      if (formik.values?.phone?.length > 6) {
        try {
          const response = await PostProfileOtpMutation(body).unwrap();

          if (response?.statusCode === 200) {
            const verifyData = {
              countryCode: phoneCode,
              phone: formik.values.phone,
              mode: "edit_profile",
            };
            successToast(response?.message || "");
            navigate("/verification", { state: verifyData });
          } else if (response?.statusCode === 503) {
            errorToast(translation.toastMessages.service_unavailable);
          }
        } catch (error: any) {
          errorToast(error?.data?.message || "");
        }
      }
    }
  };

  // for country picker
  const handleChangePhone = (phone: any, country: any) => {
    setPhoneCode(country?.dialCode);
    formik.setFieldValue("phone", phone?.replace(country.dialCode, ""));
    // setCountryName(country?.countryCode);
  };

  // for image upload
  const handleImageUpload = async (file: any) => {
    if (file?.type.includes("image")) {
      try {
        const res = await UploadMedia(file);

        if (res?.statusCode === 200) {
          setImage(res?.data);
        } else if (res?.statusCode === 503) {
          errorToast(translation.toastMessages.service_unavailable);
        }
      } catch (error: any) {
        // console.log(error);
      }
    } else {
      errorToast(translation.toastMessages.images_allowed);
    }
    return false;
  };

  useEffect(() => {
    if (user) {
      setPhoneCode(user?.countryCode || "+971");
      setImage(user?.image || "");
    } else {
      setPhoneCode("+971");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    if (token) {
      fetchData();
    }
  }, []);

  return (
    <div className="editprofile">
      <Loader
        isLoad={
          PostProfileMutationData?.isLoading ||
          isLoading ||
          GetProfileData?.isLoading
        }
      />
      {ChangePass ? (
        <h4>{translation.pridictionList.changePas}</h4>
      ) : (
        <h4>{translation.pridictionList.editprofile}</h4>
      )}
      <div className="acc_Contnr">
        <div className="position_relative prfl_img_wrp">
          <figure>
            <LazyLoadImage
              src={image || "/static/images/user_placeholder.png"}
              alt=""
              effect="blur"
              height="100%"
              width="100%"
              scrollPosition={scrollPosition}
            />
          </figure>
          <label htmlFor="icon-button-file">
            <div className="uploader">
              <TextField
                inputProps={{
                  accept: "image/*",
                }}
                id="icon-button-file"
                type="file"
                sx={{ display: "none" }}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  if (event.target.files && event.target.files[0]) {
                    setImage(URL.createObjectURL(event.target.files[0]));
                    handleImageUpload(event.target.files[0]);
                  }
                }}
              />
              <figure className="edit_img_icon">
                <LazyLoadImage
                  src="/static/img/cmra_icon.svg"
                  alt="camera icon"
                  effect="blur"
                  height="100%"
                  width="100%"
                  scrollPosition={scrollPosition}
                />
              </figure>
              <input
                type="file"
                className="file_input"
                style={{ display: "none" }}
              />
            </div>
          </label>
        </div>
        {ChangePass ? (
          <div className="inputdiv">
            <ChangePassword />
          </div>
        ) : (
          <form onSubmit={formik.handleSubmit}>
            <div className="inputdiv ">
              <TextField
                inputProps={{ maxLength: 15 }}
                placeholder={translation.pridictionList.username}
                fullWidth
                id="userName"
                name="userName"
                type="text"
                className="txt_fld user_txtfld"
                disabled
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.userName}
                helperText={formik.touched.userName && formik.errors.userName}
              />
              <TextField
                inputProps={{ maxLength: 15 }}
                placeholder={translation.pridictionList.firstname}
                fullWidth
                id="firstName"
                name="firstName"
                type="text"
                className="txt_fld"
                onChange={(val) => {
                  if (val.target.value === " " || val.target.value === ".") {
                    // formik.handleChange(null);
                  } else if (isString(val.target.value)) {
                    formik.handleChange(val);
                  }
                }}
                onBlur={formik.handleBlur}
                value={formik.values.firstName}
                helperText={formik.touched.firstName && formik.errors.firstName}
              />
              <TextField
                inputProps={{ maxLength: 15 }}
                placeholder={translation.pridictionList.lastname}
                fullWidth
                id="lastName"
                name="lastName"
                type="text"
                className="txt_fld"
                onChange={(val) => {
                  if (val.target.value === " " || val.target.value === ".") {
                    // formik.handleChange(null);
                  } else if (isString(val.target.value)) {
                    formik.handleChange(val);
                  }
                }}
                onBlur={formik.handleBlur}
                value={formik.values.lastName}
                helperText={formik.touched.lastName && formik.errors.lastName}
              />

              <div className="phoinput">
                <PhoneInput
                  excludeCountries={["bg"]}
                  value={phoneCode + formik.values.phone}
                  country="us"
                  inputClass="phoneInput"
                  buttonClass="phoneBtn"
                  onChange={(phone, country) =>
                    handleChangePhone(phone, country)
                  }
                  onBlur={formik.handleBlur}
                />
                {/* {formik.values?.phone !== userData?.phone ||
                phoneCode !== userData?.countryCode ? (
                  <div>
                    {user && (
                      <div>
                        <button
                          className={
                            formik.values?.phone?.length < 6
                              ? "vrfctn_btn2"
                              : "vrfctn_btn"
                          }
                          type="button"
                          onClick={handleNumberChange}
                        >
                          {formik.values?.phone?.length < 6
                            ? "Invalid Number"
                            : "Verify"}
                        </button>
                      </div>
                    )}
                  </div>
                ) : (
                  <div>
                    <figure className="verified">
                      <LazyLoadImage
                        src="/static/images/phonetick.svg"
                        alt=""
                        effect="blur"
                        height="100%"
                        width="100%"
                        scrollPosition={scrollPosition}
                      />
                    </figure>
                  </div>
                )} */}
                {formik.touched.phone && formik.errors.phone ? (
                  <h6 className="validation_msg2">
                    {formik.touched.phone && formik.errors.phone}
                  </h6>
                ) : (
                  ""
                )}
              </div>
              <TextField
                placeholder={translation.pridictionList.email}
                fullWidth
                id="email"
                name="email"
                type="text"
                className="txt_fld"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.email}
                helperText={formik.touched.email && formik.errors.email}
              />
              <div
                className="changpass"
                onClick={() => {
                  setChangePass(true);
                  window.scrollTo({ top: 0, behavior: "smooth" });
                }}
              >
                <h5>{translation.pridictionList.changePas}</h5>
              </div>
              <button className="btn" type="submit">
                {translation.Globals.save}
              </button>
            </div>
          </form>
        )}
      </div>
    </div>
  );
};

export default trackWindowScroll(EditProfile);
