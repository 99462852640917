/* eslint-disable no-unneeded-ternary */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Autocomplete,
  Checkbox,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

import Layout from "../../layout";
import "./PridictionList.scss";
import useTranslation from "../../hooks/Translation";
import {
  useLazyDownloadPdfQuery,
  useLazyDownloadSeasonPdfQuery,
  useLazyGetPdfQuery,
  useLazyGetSeasonPdfQuery,
} from "../../service/tournaments";
import { PdfData } from "../../types/general";
import { Loader } from "../../components";
import { errorToast } from "../../helpers/ShowToast";

const PridictionList = () => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const translation = useTranslation() as any;
  const navigate = useNavigate();
  const [getPdf] = useLazyGetPdfQuery();
  const [pdfData, setPdfData] = useState<PdfData[]>([]);
  const [getSeasonPdf] = useLazyGetSeasonPdfQuery();
  const [getdownloadPdf] = useLazyDownloadPdfQuery();
  const [getdownloadSeasonalPdf] = useLazyDownloadSeasonPdfQuery();

  const [loading, setLoading] = useState(false);
  const [selectedTournament, setSelectedTournaments] = useState("");
  const [tokenCategories, setTokenCategories] = useState([]);

  const [selectedCategory, setSelectedCategory] = useState("");

  const location = useLocation();
  const { state } = location;

  const downloadPdfData = async () => {
    try {
      setLoading(true);
      const response = await getdownloadPdf({
        id: state?.cardId,
        categoryId: state?.selectedCategoryId,
      }).unwrap();
      setLoading(false);
      if (response?.statusCode === 200) {
        const downloadLink = document.createElement("a");
        downloadLink.href = response?.data; // PDF link
        downloadLink.download = "prediction.pdf"; // Set desired filename

        // Append the link to the document body
        document.body.appendChild(downloadLink);

        // Trigger a click on the link to start the download
        downloadLink.click();

        // Remove the link from the DOM
        document.body.removeChild(downloadLink);

        // const res = await fetch(response?.data);
        // console.log(res, "rrrrrr");
        // const blob = await res.blob();
        // const url = URL.createObjectURL(blob);

        // const link = document.createElement("a");
        // link.href = url;
        // link.download = response?.data; // Customize the filename as needed
        // link.click();

        // URL.revokeObjectURL(url);
        // // window.open(response?.data, "_blank", "noopener,noreferrer");
      }
    } catch (error: any) {
      setLoading(false);
      // errorToast(error?.data?.message || "");
    }
  };

  const getTournamentPdf = async () => {
    try {
      setLoading(true);
      const response = await getPdf({
        id: state?.cardId,
        categoryId: state?.selectedCategoryId,
      }).unwrap();
      setLoading(false);
      if (response?.statusCode === 200) {
        setPdfData(response?.data);
        if (response?.data?.length) {
          downloadPdfData();
        }
      }
    } catch (error: any) {
      setLoading(false);
      // errorToast(error?.data?.message || "");
    }
  };

  const downloadSeasonalPdfData = async () => {
    try {
      setLoading(true);
      const response = await getdownloadSeasonalPdf({
        id: state?.cardId,
        tournamentId: selectedTournament ? selectedTournament : "",
        categoryId: selectedCategory ? selectedCategory : "",
      }).unwrap();
      setLoading(false);
      if (response?.statusCode === 200) {
        const res = await fetch(response?.data);
        const blob = await res.blob();
        const url = URL.createObjectURL(blob);

        const link = document.createElement("a");
        link.href = url;
        link.download = response?.data; // Customize the filename as needed
        link.click();

        URL.revokeObjectURL(url);
      }
    } catch (error: any) {
      setLoading(false);
    }
  };
  const getSeasonalPdf = async () => {
    try {
      setLoading(true);
      const response = await getSeasonPdf({
        id: state?.cardId,
        tournamentId: selectedTournament ? selectedTournament : "",
        categoryId: selectedCategory ? selectedCategory : "",
      }).unwrap();
      setLoading(false);
      if (response?.statusCode === 200) {
        setPdfData(response?.data);
      }
    } catch (error: any) {
      setLoading(false);
      // errorToast(error?.data?.message || "");
    }
  };

  useEffect(() => {
    if (state?.type === "SEASON_BANNER") {
      //   getSeasonalPdf();
    } else {
      getTournamentPdf();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleApplyFilter = () => {
    if (selectedTournament === "" || selectedCategory === "") {
      errorToast("Please select tournament and token category");
    } else {
      getSeasonalPdf();
    }
  };
  const handleDownloadSeasonPdf = () => {
    if (selectedTournament === "" || selectedCategory === "") {
      errorToast("Please select tournament and token category");
    } else {
      downloadSeasonalPdfData();
    }
  };

  return (
    <Layout>
      <Loader isLoad={loading} />
      <section className="nv_spc Pridictionlist cms">
        <div className="container">
          <div className="pridimain">
            <div className="mob_view">
              <i
                className="fa-solid fa-arrow-up-long bck_btn"
                onClick={() => navigate("/mytourneys")}
              />
            </div>
            <div>
              <div className="top_hdng_prid">
                <h2 className="hdng ">{translation.pridictionList.predlist}</h2>
                {state?.type === "SEASON_BANNER" ? (
                  <button
                    className="btn sv_btn pri_btn"
                    type="button"
                    onClick={handleDownloadSeasonPdf}
                  >
                    Download Pdf
                  </button>
                ) : null}
              </div>
              {state?.type === "SEASON_BANNER" ? (
                <div className="pdf_filters">
                  <Autocomplete
                    className="season_autocmplt"
                    disablePortal
                    id="combo-box-demo"
                    options={
                      state?.tournaments?.length ? state?.tournaments : []
                    }
                    getOptionLabel={(option: any) => option?.name}
                    onChange={(event, newValue: any) => {
                      setSelectedTournaments(newValue?._id);
                      setTokenCategories(newValue?.tokenCategories);
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="Select Tournament" />
                    )}
                  />

                  <Autocomplete
                    className="season_autocmplt"
                    disablePortal
                    disabled={!selectedTournament ? true : false}
                    id="combo-box-demo_2"
                    options={tokenCategories?.length ? tokenCategories : []}
                    getOptionLabel={(option: any) => option?.name}
                    onChange={(event, newValue: any) => {
                      setSelectedCategory(newValue?._id);
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="Select Category" />
                    )}
                  />
                  <button
                    className="btn sv_btn pri_btn"
                    type="button"
                    onClick={handleApplyFilter}
                  >
                    Apply Filter
                  </button>
                </div>
              ) : null}
            </div>
            {state?.type === "SEASON_BANNER" ? null : pdfData?.length ? (
              <h2 className="hdng clr_hdng">
                {state?.tournamentName} - {state?.categoryName} ({state?.tokens}
                )
              </h2>
            ) : null}

            {pdfData?.length ? (
              <div className="tablediv">
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell align="left">
                          {translation.pridictionList.username}
                        </TableCell>

                        {pdfData?.length
                          ? pdfData[0]?.predictions?.map((team) => {
                              return (
                                <TableCell align="center" key={team?._id}>
                                  {team?.homeTeam} V/S {team?.awayTeam}
                                </TableCell>
                              );
                            })
                          : null}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {pdfData?.map((item) => {
                        return (
                          <TableRow key={item?.userName}>
                            <TableCell align="left">{item?.userName}</TableCell>
                            {item?.predictions.map((team) => {
                              return (
                                <TableCell
                                  align="center"
                                  key={team?._id}
                                  className={
                                    team?.isFavourite ? "starmatch" : ""
                                  }
                                >
                                  {team?.homeTeam_goals}:{team?.awayTeam_goals}
                                </TableCell>
                              );
                            })}
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            ) : // <p
            //   style={{
            //     textAlign: "center",
            //     color: "white",
            //     paddingTop: "170px",
            //   }}
            // >
            //   {translation.Globals.no_data}
            // </p>
            null}
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default PridictionList;
