/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/no-array-index-key */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable object-shorthand */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useEffect, useState } from "react";
import "./Signup.scss";
import {
  Autocomplete,
  Box,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from "@mui/material";
import * as Yup from "yup";
import { useFormik } from "formik";
import {
  LazyLoadImage,
  ScrollPosition,
  trackWindowScroll,
} from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
// eslint-disable-next-line import/no-extraneous-dependencies
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Link, useNavigate } from "react-router-dom";
import useTranslation from "../../hooks/Translation";
import { generateSEKAndHash } from "../../utils/crypto";
import {
  useLazyGetTeamsQuery,
  usePostSignupMutation,
} from "../../service/Auth";
// import { errorToast } from "../../constants";
import { UploadMedia } from "../../utils/uploadMedia";
import { Loader } from "../../components";
import { errorToast } from "../../helpers/ShowToast";
import { isString } from "../../utils/validation";
import { Teams } from "../../types/User";
import {
  STORAGE_KEYS,
  getFromStorage,
  setToStorage,
} from "../../helpers/Storage";
import { useAppDispatch } from "../../hooks/Store";
import { setCredentials } from "../../reducers/authSlice";

type props = {
  scrollPosition: ScrollPosition;
};
const SignUp = ({ scrollPosition }: props) => {
  const navigate = useNavigate();
  const deviceId = navigator.userAgent;
  const dispatch = useAppDispatch();
  // const data = generateSEKAndHash();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const translation = useTranslation() as any;
  const [image, setImage] = useState<string | null>(null);
  const [showPassword, setShowPassword] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState(false);
  const [countryName, setCountryName] = useState("in");
  const [phoneCode, setPhoneCode] = useState("+971");

  const [favTeam, setFavTeam] = useState("");
  const [error, setError] = useState(false);
  const [SignUpMutation, SignUpMutationData] = usePostSignupMutation();
  const [teams, setTeams] = useState<Teams[]>();
  const [getTeams] = useLazyGetTeamsQuery();

  // for teams select
  const fetchData = async () => {
    try {
      const res = await getTeams({}).unwrap();
      if (res?.statusCode === 200) {
        setTeams(res?.data);
      } else if (res.statusCode === 503) {
        errorToast(translation.toastMessages.service_unavailable);
      }
    } catch (error: any) {
      // errorToast(error?.data?.message || "");
    }
  };

  // for image upload
  const handleImageUpload = async (file: any) => {
    if (file?.type.includes("image")) {
      try {
        const res = await UploadMedia(file);

        if (res?.statusCode === 200) {
          setImage(res?.data);
        }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        // console.log(error);
      }
    } else {
      errorToast(translation.toastMessages.images_allowed);
    }
    return false;
  };

  // formik
  const formik = useFormik({
    initialValues: {
      userName: "",
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      confirmPassword: "",
      phone: "",
      favTeam: "",
    },
    validationSchema: Yup.object({
      userName: Yup.string()
        .required(translation.validations.user_required)
        .label("Username")
        .min(2, translation.validations.min_username)
        .max(15)
        .matches(/^[a-zA-Z0-9]+$/, "Username cannot contain underscores"),
      firstName: Yup.string()
        .required(translation.validations.firstname_req)
        .label("First name")
        .min(2, translation.validations.min_firstname)
        .max(15),

      lastName: Yup.string()
        .label("Last name ")
        .min(2, translation.validations.min_lastname)
        .max(15),

      email: Yup.string()
        .required(translation.validations.email_required)
        .label("Email")
        .matches(
          // eslint-disable-next-line no-useless-escape
          /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i,
          translation.validations.valid_email
        ),
      password: Yup.string()
        .label("password")
        .required(translation.validations.password_required)
        .label("Password")
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/,
          translation.validations.pass_valid
        ),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref("password")], translation.validations.confirm_pass)
        .required(translation.validations.confirmpass_req),
      phone: Yup.string()
        .required(translation.validations.phone_required)
        .min(8, translation.validations.correct_phone)
        .max(14)
        .label("Phone number"),
    }),
    onSubmit: async () => {
      formik.setSubmitting(true);
      if (!favTeam) {
        setError(true);
        return;
      }
      setError(false);
      const fcmtoken = getFromStorage(STORAGE_KEYS.fcmToken);
      const data = {
        userName: formik.values.userName,
        firstName: formik.values.firstName,
        lastName: formik.values.lastName,
        password: formik.values.password,
        email: formik.values.email,
        favouriteTeam: favTeam,
        image: image,
        countryCode: (phoneCode.includes("+") ? "" : "+") + phoneCode,
        phone: formik.values.phone,
        appkey: new Date().toISOString(),
        deviceId: deviceId,
        deviceType: "WEB",
        deviceToken: fcmtoken,
      };
      console.log(data, "data...");

      const body = generateSEKAndHash(data);

      if (body) {
        try {
          const response = await SignUpMutation(body).unwrap();
          if (response?.statusCode === 200) {
            // successToast(response?.message || "");
            // const verifyData = {
            //   countryCode: phoneCode,

            //   phone: formik.values.phone,
            //   mode: "signup",
            // };
            // navigate("/verification", { state: verifyData, replace: true });
            setToStorage(
              STORAGE_KEYS.token,
              JSON.stringify(response?.data?.token)
            );
            setToStorage(
              STORAGE_KEYS.userData,
              JSON.stringify(response?.data || "")
            );

            dispatch(
              setCredentials({
                token: response?.data?.token || "",
                user: response?.data || null,
              })
            );
            navigate("/", { replace: true });
          } else if (response?.statusCode === 503) {
            errorToast(translation.toastMessages.service_unavailable);
          }
        } catch (error: any) {
          if (error?.data?.message) {
            errorToast(error?.data?.message || "");
          } else {
            errorToast(translation.toastMessages.no_internet);
          }
        }
      }
    },
  });

  // for country picker
  const handleChangePhone = (phone: any, country: any) => {
    setPhoneCode(country?.dialCode);
    formik.setFieldValue("phone", phone?.replace(country.dialCode, ""));
    setCountryName(country?.countryCode);
  };

  // const handleChange = (event: SelectChangeEvent) => {
  //   setFavTeam(event.target.value as string);
  // };
  const handleChange = (
    event: React.ChangeEvent<object>,
    value: Teams | null
  ) => {
    if (value !== null) {
      setFavTeam(value?._id);
    } else {
      setFavTeam(""); // Handle the case where no option is selected
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <section className="signUpmain">
      <Loader isLoad={SignUpMutationData.isLoading} />
      <div className="container">
        <div className="sigup">
          <Link to="/">
            <figure>
              <LazyLoadImage
                src="/static/images/frediction.png"
                alt=""
                effect="blur"
                height="100%"
                width="100%"
                scrollPosition={scrollPosition}
              />
            </figure>
          </Link>
          <div className="signcontainer">
            <h4>{translation.auth.signup}</h4>
            <div className="position_relative prfl_img_wrp">
              <figure className="prfl_img">
                <LazyLoadImage
                  src={
                    image !== null && image !== undefined
                      ? image
                      : "/static/images/user_placeholder.png"
                  }
                  alt=""
                  effect="blur"
                  height="100%"
                  width="100%"
                  scrollPosition={scrollPosition}
                />
              </figure>
              <label htmlFor="icon-button-file">
                <div className="uploader">
                  <TextField
                    inputProps={{
                      accept: "image/*",
                    }}
                    id="icon-button-file"
                    type="file"
                    sx={{ display: "none" }}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      if (event.target.files && event.target.files[0]) {
                        setImage(URL.createObjectURL(event.target.files[0]));
                        handleImageUpload(event.target.files[0]);
                      }
                    }}
                  />
                  <figure className="edit_img_icon">
                    <LazyLoadImage
                      src="/static/img/cmra_icon.svg"
                      alt="camera icon"
                      effect="blur"
                      height="100%"
                      width="100%"
                      scrollPosition={scrollPosition}
                    />
                  </figure>
                  <input
                    type="file"
                    className="file_input"
                    style={{ display: "none" }}
                  />
                </div>
              </label>
            </div>

            <form onSubmit={formik.handleSubmit}>
              <div className="inputdiv">
                <div className="form_group">
                  <InputLabel
                    htmlFor="outlined-adornment-password"
                    className="require inp_lbl"
                  >
                    {translation.auth.info_visible}
                  </InputLabel>
                  <TextField
                    inputProps={{ maxLength: 15 }}
                    placeholder={translation.pridictionList.username}
                    fullWidth
                    className="txt_fld"
                    id="userName"
                    name="userName"
                    type="text"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.userName}
                    helperText={
                      formik.touched.userName && formik.errors.userName
                    }
                  />
                </div>
                {/* <div className="select">
                  <Box
                    sx={{
                      minWidth: 120,
                      background: "#404040",
                      marginBottom: "20px",
                    }}
                    className="selectteam"
                  >
                    <FormControl fullWidth>
                      <Select
                        value={favTeam}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        displayEmpty
                        label="Age"
                        onChange={handleChange}
                        inputProps={{ "aria-label": "Without label" }}
                      >
                        <MenuItem value="">
                          <p> {translation.auth.select}</p>
                        </MenuItem>
                        {teams?.map((item, index) => (
                          <MenuItem key={item?._id} value={item?._id}>
                            {item?.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>
                </div> */}
                <Autocomplete
                  className="signup_autocmplt"
                  disablePortal
                  id="combo-box-demo"
                  onChange={handleChange}
                  options={teams?.length ? teams : []}
                  getOptionLabel={(option) => option?.name}
                  sx={{ width: "auto" }}
                  renderInput={(params) => (
                    <TextField {...params} label="Teams" />
                  )}
                />
                {error && !favTeam ? (
                  <h6 className="validation_msg">{translation.auth.select}</h6>
                ) : (
                  ""
                )}
                <div className="form_group">
                  <InputLabel
                    htmlFor="outlined-adornment-password"
                    className="require inp_lbl"
                  >
                    {translation.auth.info_confidential}
                  </InputLabel>
                  <TextField
                    inputProps={{ maxLength: 15 }}
                    placeholder={translation.pridictionList.firstname}
                    fullWidth
                    id="firstName"
                    name="firstName"
                    type="text"
                    className="txt_fld"
                    onChange={(val) => {
                      if (
                        val.target.value === " " ||
                        val.target.value === "."
                      ) {
                        // formik.handleChange(null);
                      } else if (isString(val.target.value)) {
                        formik.handleChange(val);
                      }
                    }}
                    onBlur={formik.handleBlur}
                    value={formik.values.firstName}
                    helperText={
                      formik.touched.firstName && formik.errors.firstName
                    }
                  />
                </div>
                <TextField
                  inputProps={{ maxLength: 15 }}
                  placeholder={translation.pridictionList.lastname}
                  fullWidth
                  id="lastName"
                  name="lastName"
                  type="text"
                  className="txt_fld"
                  onChange={(val) => {
                    if (val.target.value === " " || val.target.value === ".") {
                      // formik.handleChange(null);
                    } else if (isString(val.target.value)) {
                      formik.handleChange(val);
                    }
                  }}
                  onBlur={formik.handleBlur}
                  value={formik.values.lastName}
                  helperText={formik.touched.lastName && formik.errors.lastName}
                />
                <div className="phoinput">
                  <PhoneInput
                    excludeCountries={["bg"]}
                    country="us"
                    value={phoneCode + formik.values.phone}
                    inputClass="phoneInput"
                    buttonClass="phoneBtn"
                    onChange={(phone, country) =>
                      handleChangePhone(phone, country)
                    }
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.phone && formik.errors.phone ? (
                    <h6 className="validation_msg2">
                      {formik.touched.phone && formik.errors.phone}
                    </h6>
                  ) : (
                    ""
                  )}
                </div>

                <TextField
                  placeholder={translation.pridictionList.email}
                  fullWidth
                  id="email"
                  name="email"
                  type="text"
                  className="txt_fld"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.email}
                  helperText={formik.touched.email && formik.errors.email}
                />

                <TextField
                  className="adornment txt_fld"
                  placeholder={translation.pridictionList.pass}
                  fullWidth
                  type={showPassword ? "text" : "password"}
                  id="password"
                  name="password"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.password}
                  helperText={formik.touched.password && formik.errors.password}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment
                        position="end"
                        className="iconador"
                        sx={{ top: "27px" }}
                      >
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => {
                            setShowPassword(!showPassword);
                            const inputEl: HTMLInputElement | null =
                              document.getElementById(
                                "password"
                              ) as HTMLInputElement | null;
                            if (inputEl) {
                              setTimeout(() => {
                                inputEl.setSelectionRange(
                                  inputEl.value.length,
                                  inputEl.value.length
                                );
                              }, 0);
                            }
                          }}
                          onMouseDown={(event) => event.preventDefault()}
                          edge="end"
                          className="eye_icon"
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <TextField
                  className="adornment   txt_fld"
                  placeholder={translation.pridictionList.confirmpass}
                  fullWidth
                  type={confirmPassword ? "text" : "password"}
                  id="confirmPassword"
                  name="confirmPassword"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.confirmPassword}
                  helperText={
                    formik.touched.confirmPassword &&
                    formik.errors.confirmPassword
                  }
                  InputProps={{
                    endAdornment: (
                      <InputAdornment
                        position="end"
                        className="iconador"
                        sx={{ top: "27px" }}
                      >
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => {
                            setConfirmPassword(!confirmPassword);
                            const inputEl: HTMLInputElement | null =
                              document.getElementById(
                                "confirmPassword"
                              ) as HTMLInputElement | null;
                            if (inputEl) {
                              setTimeout(() => {
                                inputEl.setSelectionRange(
                                  inputEl.value.length,
                                  inputEl.value.length
                                );
                              }, 0);
                            }
                          }}
                          onMouseDown={(event) => event.preventDefault()}
                          edge="end"
                          className="eye_icon"
                        >
                          {confirmPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <button
                  className="btn submit_btn"
                  type="submit"
                  style={{ width: "100%" }}
                  disabled={formik.isSubmitting}
                  onClick={() => setError(true)}
                >
                  {translation.auth.signup}
                </button>
              </div>
            </form>
            <div className="haveAcc">
              <p>
                {translation.auth.haveacc}{" "}
                <span onClick={() => navigate("/login", { replace: true })}>
                  {translation.auth.signin}
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default trackWindowScroll(SignUp);
