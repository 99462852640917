/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable no-nested-ternary */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/no-danger */
/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Dispatch, Key, SetStateAction, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import {
  LazyLoadImage,
  ScrollPosition,
  trackWindowScroll,
} from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import useTranslation from "../../hooks/Translation";
import RightDrawer from "./RightDrawer";
import { Loader, MatchCard } from "../../components";
import {
  preDictionByUser,
  Season,
  Tournaments,
  TourneyDetails,
} from "../../types/general";
import {
  useLazyGetPredictionByUserNameQuery,
  useLazyGetTournamentDetailsQuery,
} from "../../service/tournaments";

import { errorToast } from "../../helpers/ShowToast";

const CustomPrevArrow = (props: any) => {
  const { onClick } = props;

  return (
    <div className="custom-prev-arrow" onClick={onClick}>
      <ChevronLeftIcon />
    </div>
  );
};

const CustomNextArrow = (props: any) => {
  const { onClick } = props;

  return (
    <div className="custom-next-arrow" onClick={onClick}>
      <ChevronRightIcon />
    </div>
  );
};

interface MatchDetailType {
  cardId: string;
  matchDetail: any | undefined;
  seasonData: Season[];
  tourneyData: Tournaments[] | undefined;
  setMatchDetail: Dispatch<SetStateAction<TourneyDetails | undefined>>;
  setSelectedMatchId: Dispatch<SetStateAction<string>>;
  activeTab?: string;
  state?: string;
  selectedCategoryId?: string | null | undefined;
  cardFirstDate?: string | null | undefined;
  setCardFirstDate?: any;
  userEntries?: any;
  scrollPosition: ScrollPosition;
}

const MatchDetail = ({
  cardId,
  matchDetail,
  seasonData,
  tourneyData,
  setSelectedMatchId,
  setMatchDetail,
  activeTab,
  state,
  selectedCategoryId,
  cardFirstDate,
  setCardFirstDate,
  userEntries,
  scrollPosition,
}: MatchDetailType) => {
  const navigate = useNavigate();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const translation = useTranslation() as any;
  const [sidebarVisible, setSidebarVisible] = useState(false);
  const [ActiveDrawer, SetActiveDrawer] = useState(1);
  const [userName, setUserName] = useState("");
  const [userImage, setUserImage] = useState("");
  const [selectedFixtureId, setSelectedFixtureId] = useState<string>("");
  const [predictions, setPredictions] = useState<preDictionByUser[]>();
  const [predictionByUserName] = useLazyGetPredictionByUserNameQuery();
  const [getTourneyDetailData] = useLazyGetTournamentDetailsQuery();
  const [loading, setLoading] = useState(false);
  console.log(selectedFixtureId, "selectedFixtureId");

  const userData = {
    page: "match_Detail",
  };

  const name = matchDetail?._id;

  const filterMatches = tourneyData?.find(
    (obj) => obj?._id === matchDetail?._id
  );
  const sortedFilterMatches = filterMatches?.fixtures
    ? {
        ...filterMatches,
        fixtures: [...filterMatches.fixtures].sort((a, b) => {
          const dateA = new Date(a.fixture.date).getTime();
          const dateB = new Date(b.fixture.date).getTime();

          return dateA - dateB;
        }),
      }
    : undefined;

  if (sortedFilterMatches) {
    console.log(sortedFilterMatches);
  } else {
    console.error("filterMatches is undefined");
  }

  console.log(sortedFilterMatches, "sortedFilterMatches");
  console.log(tourneyData, "tourneyData");
  console.log(matchDetail, "matchDetail");
  console.log(filterMatches, "filterMatches");

  const getTournamentDetails = async (
    id: string,
    key: boolean,
    fixtureId: string | null | undefined,
    categoryId: string
  ) => {
    try {
      setLoading(true);
      const response = await getTourneyDetailData({
        type: "live",
        id,
        fixtureId: key ? fixtureId : null,
        categoryId,
      }).unwrap();
      setLoading(false);
      if (response?.statusCode === 200) {
        setSelectedMatchId(id);
        setMatchDetail(response?.data);
      } else if (response.statusCode === 503) {
        errorToast(translation.toastMessages.service_unavailable);
      }
    } catch (error: any) {
      setLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    if (sortedFilterMatches?.fixtures?.length) {
      setSelectedFixtureId(sortedFilterMatches?.fixtures[0]?.fixture_id || "");
    } else {
      setSelectedFixtureId("");
    }
  }, []);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [newCls, setNewCls] = useState(false);

  useEffect(() => {
    if (tourneyData?.length) {
      const fixtureDates = tourneyData?.length
        ? tourneyData[0]?.fixtures?.map((fixture) => fixture?.fixture?.date)
        : null;
      const filteredDates = fixtureDates?.filter((date) => date !== "");

      const dateObjects = filteredDates?.map((date) => new Date(date));
      dateObjects?.sort((a, b) => a.getTime() - b.getTime());
      const firstDate =
        dateObjects && dateObjects?.length > 0 ? dateObjects[0] : null;

      if (setCardFirstDate) {
        setCardFirstDate(firstDate);
      }
    }
  }, [activeTab, tourneyData]);

  const handleSlideChange = async (index: number) => {
    if (
      sortedFilterMatches &&
      matchDetail &&
      sortedFilterMatches?.fixtures?.length
    ) {
      const data = sortedFilterMatches?.fixtures[index];
      setSelectedFixtureId(data?.fixture_id || "");
      getTournamentDetails(
        matchDetail?._id,
        matchDetail?.isLive,
        data?.fixture_id,
        sortedFilterMatches?.categoryId?._id
      );
    }
  };

  const settings = {
    dots: true,
    infinite: true,
    // arrows: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    afterChange: handleSlideChange,
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
  };

  const handleCardClick = () => {};

  const getPredictionsByUsername = async (uName: string) => {
    try {
      setLoading(true);
      const response = await predictionByUserName({
        id:
          activeTab === "history"
            ? matchDetail?.userNames[0]?.tournament_id?._id
            : matchDetail?._id,
        userName: uName,
        categoryId:
          activeTab === "history"
            ? matchDetail?.userNames[0]?.tokenCategory
            : matchDetail?.categoryId?._id,
      }).unwrap();
      setLoading(false);
      if (response?.statusCode === 200) {
        setPredictions(response?.data);
        if (uName) {
          SetActiveDrawer(3);
          setSidebarVisible(true);
        }
      } else {
        // console.log("ERROR");
      }
    } catch (error: any) {
      setLoading(false);
      errorToast(error?.data?.message || "");
    }
  };
  const handleUserPredictionsClick = (item: any) => {
    // if (activeTab === "history") {
    //   setUserName("");
    // } else {
    setUserName(item?.userName);
    getPredictionsByUsername(item?.userName);
    setUserImage(item?.image);
    // }
  };

  return (
    <>
      <RightDrawer
        sidebarVisible={sidebarVisible}
        setSidebarVisible={setSidebarVisible}
        ActiveDrawer={ActiveDrawer}
        predictions={predictions}
        userName={userName}
        userImage={userImage}
        tournamentId={cardId}
        selectedFixtureId={selectedFixtureId}
      />
      <Loader isLoad={loading} />
      <div className="trTop">
        <div>
          {matchDetail?.isLive ? (
            <h4 className="tourney_tm2">Active Tournament</h4>
          ) : activeTab === "history" ? (
            <h4 className="tourney_tm2">Tournament Finished</h4>
          ) : (
            <h4>
              Tournament starting on{" "}
              {moment(cardFirstDate).format("dddd Do MMM HH:mm")}
            </h4>
          )}
        </div>

        {matchDetail?.fixtureDetails?.fixture?.status?.short === "1H" ||
        matchDetail?.fixtureDetails?.fixture?.status?.short === "2H" ? (
          <div className="live">
            <figure>
              <LazyLoadImage
                src="/static/images/live.svg"
                alt=""
                effect="blur"
                scrollPosition={scrollPosition}
                height="100%"
                width="100%"
              />
            </figure>
            <h5 style={{ textTransform: "uppercase" }}>
              {translation.tourneyCreated.live}
            </h5>
          </div>
        ) : null}
        <div className="trIcn">
          {matchDetail?.isLive ? (
            <div
              onClick={() =>
                navigate("/predictionlist", {
                  state: {
                    cardId,
                    selectedCategoryId,
                    tournamentName: matchDetail?.name,
                    categoryName: matchDetail?.categoryId?.name,
                    tokens: matchDetail?.categoryId?.tokens,
                  },
                })
              }
            >
              <figure>
                <LazyLoadImage
                  src="/static/images/pdf.svg"
                  alt=""
                  effect="blur"
                  height="100%"
                  width="100%"
                  scrollPosition={scrollPosition}
                />
              </figure>
            </div>
          ) : null}
          {activeTab === "history" ? null : (
            <div
              onClick={() => {
                setSidebarVisible(true);
                SetActiveDrawer(1);
              }}
            >
              <figure>
                <LazyLoadImage
                  src="/static/images/userarr.png"
                  alt=""
                  effect="blur"
                  height="100%"
                  width="100%"
                  scrollPosition={scrollPosition}
                />
              </figure>
            </div>
          )}

          {activeTab === "history" || state === "tournamentDetail" ? null : (
            <div
              onClick={() => {
                setSidebarVisible(true);
                SetActiveDrawer(2);
              }}
            >
              <figure>
                <LazyLoadImage
                  src="/static/images/chat.png"
                  alt=""
                  effect="blur"
                  height="100%"
                  width="100%"
                  scrollPosition={scrollPosition}
                />
              </figure>
            </div>
          )}
        </div>
      </div>
      <div className="matchmain_mob trLft">
        <div className="matchmain" style={{ width: "100%" }}>
          {tourneyData?.length
            ? tourneyData?.map((item) => {
                return (
                  <div key={item._id} onClick={() => handleCardClick()}>
                    {cardId === item._id &&
                      selectedCategoryId === item?.categoryId?._id && (
                        <MatchCard
                          onSelectCard={() => setSelectedMatchId("")}
                          id={item._id}
                          image={item?.image}
                          fixtures={item?.fixtures}
                          title={item.name}
                          totalTokens={item?.totalTokens}
                          setNewCls={setNewCls}
                          tokenCat={item}
                          cutoff=""
                          categoryId={item?.categoryId}
                          selectedCategoryId={selectedCategoryId}
                          selectedMatchId={cardId}
                          setCardFirstDate={setCardFirstDate}
                          isLive={item?.isLive}
                          userEntries={userEntries}
                          isFinished={item?.isFinished}
                          totalUsers={item?.totalUsers}
                          userNames={item?.userNames}
                        />
                      )}
                  </div>
                );
              })
            : null}
        </div>
        <div className="matchmain" style={{ width: "100%" }}>
          {seasonData.length
            ? seasonData?.map((item) => {
                return (
                  <div key={item._id} onClick={() => handleCardClick()}>
                    {cardId === item._id && (
                      <MatchCard
                        onSelectCard={() => setSelectedMatchId("")}
                        id={item._id}
                        image={item?.image}
                        type={item?.type}
                        tokenFee={item?.tokenFee}
                        season={item?.title}
                        startDate={item?.startDate}
                        startTime={item?.startTime}
                        endDate={item?.endDate}
                        users={item?.users || undefined}
                        isSubscribed={item?.isSubscribed}
                        setNewCls={setNewCls}
                        cutoff=""
                      />
                    )}
                  </div>
                );
              })
            : null}
        </div>
        {matchDetail?.isLive ? (
          <div className="sliderdiv">
            <Slider {...settings}>
              {sortedFilterMatches && sortedFilterMatches?.fixtures?.length
                ? sortedFilterMatches?.fixtures?.map((item) => {
                    return (
                      <div
                        onClick={() => {
                          setSidebarVisible(true);
                          SetActiveDrawer(4);
                          setSelectedFixtureId(item?.fixture_id || "");
                        }}
                        key={item?._id}
                      >
                        <div className="matchteams">
                          <div className="team1">
                            <figure>
                              <LazyLoadImage
                                src={
                                  item?.teams?.home?.logo ||
                                  "/static/images/team4.png"
                                }
                                alt=""
                                effect="blur"
                                height="100%"
                                width="100%"
                                scrollPosition={scrollPosition}
                              />
                            </figure>
                            <div className="scrodiv">
                              <h2>
                                {matchDetail?.fixtureDetails?.goals?.home ===
                                null
                                  ? null
                                  : matchDetail?.fixtureDetails?.goals?.home}
                              </h2>

                              <h2>{item?.teams?.home?.name || ""}</h2>
                            </div>
                          </div>
                          <figure className="vs">
                            <LazyLoadImage
                              src="/static/images/vs.png"
                              alt=""
                              effect="blur"
                              height="100%"
                              width="100%"
                              scrollPosition={scrollPosition}
                            />
                          </figure>
                          <div className="team1 team2">
                            <div className="scrodiv">
                              <h2>
                                {matchDetail?.fixtureDetails?.goals?.away ===
                                null
                                  ? null
                                  : matchDetail?.fixtureDetails?.goals?.away}
                              </h2>
                              <h2>{item?.teams?.away?.name || ""}</h2>
                            </div>

                            <figure>
                              <LazyLoadImage
                                src={
                                  item?.teams?.away?.logo ||
                                  "/static/images/team2.png"
                                }
                                alt=""
                                effect="blur"
                                height="100%"
                                width="100%"
                                scrollPosition={scrollPosition}
                              />
                            </figure>
                          </div>
                        </div>
                      </div>
                    );
                  })
                : null}
            </Slider>
          </div>
        ) : null}

        {matchDetail?.seasonBanner ? (
          <div
            onClick={() =>
              navigate(
                `/seasonaltournament/${matchDetail?.seasonBanner?._id}`,
                {
                  state: "seasonal_tornament",
                }
              )
            }
            className="bonmain bonmain_tour"
          >
            <div
              className="bonusBnr"
              style={{
                backgroundImage: `url(${matchDetail?.seasonBanner?.image})`,
              }}
            >
              <h1>{matchDetail?.seasonBanner?.title} </h1>
              {matchDetail?.seasonBanner?.bannerDescription && (
                <p
                  dangerouslySetInnerHTML={{
                    __html: matchDetail?.seasonBanner.bannerDescription,
                  }}
                />
              )}

              <h4>
                {translation.Globals.regFee}:{" "}
                <span>{matchDetail?.seasonBanner?.tokenFee}</span>
              </h4>
            </div>
          </div>
        ) : null}
      </div>

      <div className="btmdiv">
        {activeTab === "history" || state === "tournamentDetail" ? null : (
          <div className="matchdetail">
            {matchDetail?.isLive ? (
              <div
                className="h4div"
                onClick={() => {
                  setSidebarVisible(true);
                  SetActiveDrawer(4);
                }}
              >
                <h4>{translation.mytourney.matchdetail}</h4>
              </div>
            ) : null}

            <div
              className={matchDetail?.isLive ? "h4div " : "h4div2"}
              onClick={() =>
                navigate(`/entries/${name}`, {
                  state: {
                    userData,
                    live: matchDetail?.isLive,
                    tournamentName: matchDetail?.name,
                    tournamentImage: matchDetail?.image,
                    tournamentFixtures: matchDetail?.fixtures,
                    cutoff: matchDetail?.cutOffTime,
                    categoryId: matchDetail?.categoryId?._id,
                  },
                })
              }
            >
              <h4>
                {translation.mytourney.pridiction} (
                {matchDetail?.myPredictionCount || 0})
              </h4>
            </div>
          </div>
        )}

        {activeTab === "history" ? (
          <h4 className="heading">
            {matchDetail?.userNames?.length
              ? matchDetail?.userNames?.length
              : "0"}{" "}
            {translation.tourneyCreated.players}
          </h4>
        ) : (
          <h4 className="heading">
            {translation.mytourney.total} {matchDetail?.totalEntries}{" "}
            {translation.mytourney.entries}
          </h4>
        )}
        <table className="userlisting table_listing">
          <thead className="head1">
            <tr>
              <th>
                {" "}
                <h5>{translation.mytourney.rank}</h5>
              </th>
              {matchDetail?.isLive ? (
                <th>
                  <h5>Predictions</h5>
                </th>
              ) : null}
              {activeTab === "history" || matchDetail?.isLive ? (
                <th>
                  <h5>Pts</h5>
                </th>
              ) : (
                <th>
                  <h5>{translation.mytourney.status}</h5>
                </th>
              )}
              {matchDetail?.isLive ? (
                <th>
                  <h5>Total</h5>
                </th>
              ) : null}
            </tr>
          </thead>
          <tbody className="users">
            {matchDetail?.userNames?.map((item: any) => {
              return (
                <tr
                  className="user1"
                  key={item?.userName}
                  onClick={() => handleUserPredictionsClick(item)}
                >
                  <td>
                    <div className="userleft">
                      <figure>
                        <LazyLoadImage
                          src={
                            item?.image
                              ? item?.image
                              : "/static/images/user_placeholder.png"
                          }
                          alt=""
                          effect="blur"
                          height="100%"
                          width="100%"
                          scrollPosition={scrollPosition}
                        />
                      </figure>
                      <h4
                        className={
                          item?.rank === 1
                            ? "golden_txt"
                            : item?.rank === 2
                            ? "silver_txt"
                            : item?.rank === 3
                            ? "bronze_txt"
                            : ""
                        }
                      >
                        {matchDetail?.isLive || activeTab === "history" ? (
                          <span style={{ fontWeight: "bold" }}>
                            {item?.rank || " "}.{" "}
                          </span>
                        ) : null}

                        {item?.userName || ""}
                      </h4>
                    </div>
                  </td>
                  {matchDetail?.isLive ? (
                    <td>
                      <h3>{item?.prediction || ""}</h3>
                    </td>
                  ) : null}
                  {activeTab === "history" || matchDetail?.isLive ? (
                    <td>
                      <h3>{item?.points || 0}</h3>{" "}
                    </td>
                  ) : (
                    <td>
                      {" "}
                      <h3>{translation.Globals.joined}</h3>
                    </td>
                  )}
                  {matchDetail?.isLive ? (
                    <td>
                      <h3>{item?.totalPoints || 0}</h3>
                    </td>
                  ) : null}
                </tr>
              );
            })}
          </tbody>
          <tfoot>
            <tr>
              <td colSpan={2}>
                {matchDetail?.isLive ||
                activeTab === "history" ||
                matchDetail?.isFinished ? null : (
                  <button
                    className="newentry"
                    type="submit"
                    onClick={() =>
                      navigate(`/predictions/${matchDetail?._id}`, {
                        state: {
                          tournamentName: matchDetail?.name,
                          tournamentFixtures: matchDetail?.fixtures,
                          cutoff: matchDetail?.cutOffTime,
                        },
                      })
                    }
                  >
                    {translation.mytourney.newentry}
                  </button>
                )}
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
    </>
  );
};

export default trackWindowScroll(MatchDetail);
