/* eslint-disable react/no-danger */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-unsafe-optional-chaining */
import React, { useEffect, useState } from "react";
import moment from "moment";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useLocation, useNavigate } from "react-router-dom";
import useTranslation from "../../hooks/Translation";
import "./PointsCalculation.scss";
import Header from "../../layout/header";
import useAuth from "../../hooks/UseAuth";
import Layout from "../../layout";

const PointsCalculation = () => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const translation = useTranslation() as any;
  const navigate = useNavigate();
  const location = useLocation();
  const [matchTimer, setMatchTimer] = useState<string>("");
  const [daysDifference, setDaysDifference] = useState(0);
  const [timeDifference, setTimeDifference] = useState(0);
  const [startDate, setStartDate] = useState("");
  const [currentDate, setCurrentDate] = useState("");

  const { state } = location;
  console.log(state, "statepointsCal");

  const data = state?.response?.data?.tournament;
  const seasonBanner = state?.response?.data?.seasonBanner;
  const user = useAuth();

  const copiedFixtures = data ? [...data?.fixtures] : [];
  const sortedFixtures = copiedFixtures?.sort((a, b) => {
    const dateA = moment(a.fixture.date) as any;
    const dateB = moment(b.fixture.date) as any;
    return dateA - dateB;
  });

  const firstDate = sortedFixtures?.length
    ? sortedFixtures[0]?.fixture.date
    : undefined;
  useEffect(() => {
    let timer: NodeJS.Timeout;

    if (firstDate) {
      const updateTimeLeft = () => {
        const currentDateTime = moment();

        const targetDateTime = moment(firstDate);

        const timeDiff = targetDateTime.diff(currentDateTime);

        if (timeDiff > 0) {
          const duration = moment.duration(timeDiff);

          const days = String(duration.days()).padStart(2, "0");
          const hours = String(duration.hours()).padStart(2, "0");
          const minutes = String(duration.minutes()).padStart(2, "0");
          const seconds = String(duration.seconds()).padStart(2, "0");

          const remainingDaysNumber = parseInt(days, 10);
          // const remainingHoursNumber = parseInt(hours, 10);
          const timerString = `${hours}h ${minutes}m ${seconds}s`;
          if (remainingDaysNumber > 1) {
            setMatchTimer(`${days}  days`);
          } else {
            setMatchTimer(timerString);
          }
        } else {
          clearInterval(timer);
          setMatchTimer("");
        }
      };

      timer = setInterval(updateTimeLeft, 1000);
      updateTimeLeft();
    } else {
      console.log("No fixtures found.");
    }

    return () => {
      clearInterval(timer);
    };
  }, [firstDate]);

  useEffect(() => {
    if (seasonBanner) {
      const startDateTime = moment(
        `${seasonBanner.startDate} ${seasonBanner.startTime}`,
        "YYYY-MM-DD HH:mm"
      );
      const currentDateTime = moment().format("YYYY-MM-DD");

      setCurrentDate(currentDateTime);
      setStartDate(seasonBanner?.startDate);
      const daysDiff = startDateTime.diff(currentDateTime, "days");
      const hoursDiff = startDateTime.diff(currentDateTime, "hours");
      const hoursRemaining = ((hoursDiff % 24) + 24) % 24;
      setDaysDifference(daysDiff);
      setTimeDifference(hoursRemaining);
    }
  }, [seasonBanner]);

  return (
    <Layout>
      <div className="nv_spc cm_trny_img2 trny_crtd cms">
        <div className="container">
          <div className="pnt_clcltn sb">
            <div className="mob_view">
              <i
                className="fa-solid fa-arrow-up-long bck_btn"
                onClick={() =>
                  navigate(`/predictions/${state}`, {
                    state: { show_btn: "show_btn", tournamentId: state },
                  })
                }
              />
            </div>
            <div
              className="new_dv"
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <KeyboardBackspaceIcon
                sx={{
                  fontSize: "30px",
                  color: "white",
                  marginRight: "10px",
                  paddingBottom: "4px",
                  cursor: "pointer",
                }}
                className="arw_icn_nw"
                onClick={() =>
                  navigate(`/predictions/${state}`, {
                    state: { show_btn: "show_btn", tournamentId: state },
                  })
                }
              />
              <h2>Points Calculation</h2>
            </div>
          </div>
          <div className="mn_trny_crtd nw_wdth">
            <div className="trnys">
              <div className="trny_ryt">
                <h5 className="yellow_text crtd">
                  Each match gets one of the below points:
                </h5>
                <h5 className="">
                  The pot get distributed among top 3 entries, first entry gets
                  50%, second entry gets 30%, third entry gets 20%
                </h5>
                <ul>
                  <li>
                    <h5>
                      Correct Score ={" "}
                      <span className="yellow_text">5 Points</span>
                    </h5>
                  </li>
                  <li>
                    <h5>
                      Correct Winning Team and Correct Goal Difference ={" "}
                      <span className="yellow_text"> 3 Points</span>
                    </h5>
                  </li>
                  <li>
                    <h5>
                      Correct Winning Team Only ={" "}
                      <span className="yellow_text">2 Points</span>
                    </h5>
                  </li>
                  <li>
                    <h5>
                      Draw with 1 Goal Difference ={" "}
                      <span className="yellow_text">3 Points</span>
                    </h5>
                  </li>
                  <li>
                    <h5>
                      Draw with more than 1 Goal Difference ={" "}
                      <span className="yellow_text">2 Points</span>
                    </h5>
                  </li>
                  <li>
                    <h5>
                      Incorrect winning team ={" "}
                      <span className="yellow_text">0 point</span>
                    </h5>
                  </li>
                </ul>
                <h5 className="yellow_text">
                  <i> *Star Match gives you Double Points</i>
                </h5>
                <h5 className="yellow_text crtd " style={{ textAlign: "left" }}>
                  Example:
                </h5>
                <h5 style={{ textAlign: "left" }}>
                  If the match ends as 3 –1, then if your prediction is:
                </h5>
                <div className="pnts_clcltn">
                  <div className="pnts_dv">
                    <h5>3 –1</h5>
                    <h5 className="yellow_text">5 points</h5>
                  </div>
                  <div className="pnts_dv">
                    <h5>2 –0</h5>
                    <h5 className="yellow_text">3 points</h5>
                  </div>
                  <div className="pnts_dv">
                    <h5>3 –0 </h5>
                    <h5 className="yellow_text">2 points</h5>
                  </div>
                  <div className="pnts_dv">
                    <h5>1 –2</h5>
                    <h5 className="yellow_text">0 points</h5>
                  </div>
                </div>
                <h5 style={{ textAlign: "left" }}>
                  If the match ends as 2 –2, then if your prediction is:
                </h5>
                <div className="pnts_clcltn">
                  <div className="pnts_dv">
                    <h5>2 –2</h5>
                    <h5 className="yellow_text">5 points</h5>
                  </div>
                  <div className="pnts_dv">
                    <h5>1 –1 </h5>
                    <h5 className="yellow_text">3 points</h5>
                  </div>
                  <div className="pnts_dv">
                    <h5>0 –0 </h5>
                    <h5 className="yellow_text">2 points</h5>
                  </div>
                  <div className="pnts_dv">
                    <h5>2 –1</h5>
                    <h5 className="yellow_text">0 points</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default PointsCalculation;
