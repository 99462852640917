/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable object-shorthand */
import React, { useEffect, useState } from "react";
// eslint-disable-next-line import/no-extraneous-dependencies
import OtpInput from "react-otp-input";
import {
  LazyLoadImage,
  ScrollPosition,
  trackWindowScroll,
} from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

import { Box, Modal } from "@mui/material";
import { Link, useNavigate, useLocation } from "react-router-dom";
import useTranslation from "../../hooks/Translation";
import {
  usePostForgotPasswordMutation,
  usePostOtpVerificationMutation,
  usePostResendOtpMutation,
} from "../../service/Auth";

import { useAppDispatch } from "../../hooks/Store";
import { setCredentials, temporaryToken } from "../../reducers/authSlice";
import { generateSEKAndHash } from "../../utils/crypto";
import { STORAGE_KEYS, setToStorage } from "../../helpers/Storage";
import { errorToast, successToast } from "../../helpers/ShowToast";
import { Loader } from "../../components";

const otpStyle = {
  padding: "15px",
  border: "none",
  borderBottom: "1px solid #B4B4B4",
  margin: "22px",
};
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 300,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 3,
  borderRadius: 3,
  outline: "none",
  border: "none",
  cursor: "pointer",
};
type props = {
  scrollPosition: ScrollPosition;
};
const Verification = ({ scrollPosition }: props) => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  // const data = generateSEKAndHash();
  const deviceId = navigator.userAgent;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const translation = useTranslation() as any;
  const [open, setOpen] = React.useState(false);
  const [otp, setOtp] = useState("");
  const [error, setError] = useState<boolean>(false);
  const [countDown, setCountDown] = useState<number>(59);

  const [optVerificationMutation, optVerificationMutationData] =
    usePostOtpVerificationMutation();
  const [resendOtpMutation, { isLoading }] = usePostResendOtpMutation();
  const [ForgotPasswordMutation, ForgotPasswordMutationData] =
    usePostForgotPasswordMutation();

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (countDown > 0) {
        setCountDown(countDown - 1);
      }
    }, 1000);
    return () => clearInterval(intervalId);
  }, [countDown]);

  const handleClick = () => {
    setOpen(false);
    navigate("/");
  };
  const handleSubmit = async () => {
    if (otp?.length === 4) {
      setError(false);
      // setOpen(true);

      const data = {
        email: state?.email,
        code: otp,
        appkey: new Date().toISOString(),
        deviceId: deviceId,
      };
      const body = generateSEKAndHash(data);
      if (body) {
        try {
          const response = await optVerificationMutation(body).unwrap();
          if (response?.statusCode === 200) {
            if (state?.mode === "forget") {
              dispatch(temporaryToken({ tempToken: response?.data?.token }));
              navigate("/resetpassword", { replace: true });
            } else if (state?.mode === "signup") {
              setToStorage(
                STORAGE_KEYS.token,
                JSON.stringify(response?.data?.token)
              );
              setToStorage(
                STORAGE_KEYS.userData,
                JSON.stringify(response?.data || "")
              );

              dispatch(
                setCredentials({
                  token: response?.data?.token || "",
                  user: response?.data || "",
                })
              );
              navigate("/", { replace: true });
            } else if (state?.mode === "edit_profile") {
              navigate("/userprofile", { replace: true });
              successToast(translation.toastMessages.phone_changed);
            }
          } else if (response?.statusCode === 503) {
            errorToast(translation.toastMessages.service_unavailable);
          }
          // eslint-disable-next-line @typescript-eslint/no-shadow
        } catch (error: any) {
          if (error?.data?.message) {
            errorToast(error?.data?.message || "");
          } else {
            errorToast(translation.toastMessages.no_internet);
          }
        }
      }
    } else {
      setError(true);
    }
  };

  const handleResendOtp = async () => {
    const data = {
      // countryCode:
      //   // eslint-disable-next-line no-unsafe-optional-chaining
      //   (state?.countryCode.includes("+") ? "" : "+") + state?.countryCode,
      // phone: state?.phone,
      email: state?.email,
      // type: state?.mode,
      appkey: new Date().toISOString(),
      deviceId: deviceId,
    };
    console.log(data, "resend");

    const body = generateSEKAndHash(data);
    if (body) {
      try {
        const res = await ForgotPasswordMutation(body).unwrap();
        if (res?.statusCode === 200) {
          successToast(res?.message || "");
          setCountDown(59);
        } else if (res?.statusCode === 503) {
          errorToast(translation.toastMessages.service_unavailable);
        }
        // eslint-disable-next-line @typescript-eslint/no-shadow
      } catch (error: any) {
        if (error?.data?.message) {
          errorToast(error?.data?.message || "");
        } else {
          errorToast(translation.toastMessages.no_internet);
        }
      }
    }
  };

  useEffect(() => {
    if (state?.mode === null) {
      navigate("/signup", { replace: true });
    }
  }, [navigate, state?.mode]);

  return (
    <section className="signUpmain">
      <Loader isLoad={optVerificationMutationData.isLoading || isLoading} />
      <div className="container">
        <div className="sigup">
          <Link to="/">
            <figure>
              <LazyLoadImage
                src="/static/images/frediction.png"
                alt=""
                effect="blur"
                height="100%"
                width="100%"
                scrollPosition={scrollPosition}
              />
            </figure>
          </Link>
          <div className="close" onClick={() => navigate("/signup")}>
            <figure>
              <LazyLoadImage
                src="/static/images/close-square.svg"
                alt=""
                effect="blur"
                height="100%"
                width="100%"
                scrollPosition={scrollPosition}
              />
            </figure>
          </div>
          <div className="forgotcontainer">
            <h4>{translation.auth.verification}</h4>
            <p>
              {translation.auth.verify_des}{" "}
              <span className="mob_block">
                {translation.auth.veri_num}
                {state?.email}
                {/* {(state?.countryCode.includes("+") ? "" : "+") +
                  // eslint-disable-next-line no-unsafe-optional-chaining
                  state?.countryCode}{" "}
                {state?.phone} */}
              </span>{" "}
            </p>
            <div className="inputdiv">
              <div className="otpInput">
                <OtpInput
                  value={otp}
                  onChange={setOtp}
                  numInputs={4}
                  renderInput={(props) => <input {...props} />}
                  inputStyle={otpStyle}
                  inputType="tel"
                />
                {error && otp.length !== 4 ? (
                  <h6 className="validation_msg otp_err">
                    {translation.validations.required_field}
                  </h6>
                ) : (
                  ""
                )}
              </div>
              <button
                className="btn submit_btn"
                type="submit"
                style={{ width: "100%" }}
                onClick={() => handleSubmit()}
              >
                {translation.Globals.continue}
              </button>
              <h3>
                {translation.auth.code_expire}{" "}
                <span className="text_mob_dnger countr_wdh">
                  {countDown < 10 ? `00: 0${countDown}` : `00: ${countDown}`}
                </span>{" "}
              </h3>

              {countDown === 0 && (
                <h4 onClick={() => handleResendOtp()}>
                  {" "}
                  {translation.auth.resend}
                </h4>
              )}
              {/* )} */}
            </div>
          </div>
        </div>
      </div>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="congrats_pop"
      >
        <Box sx={style} className="congrat_wrp" onClick={handleClick}>
          <div className="congrats">
            <figure>
              <LazyLoadImage
                src="/static/images/congratulation.svg"
                alt=""
                effect="blur"
                height="100%"
                width="100%"
                scrollPosition={scrollPosition}
              />
            </figure>

            <h2> {translation.auth.congrats}</h2>

            <p>{translation.auth.profile_done}</p>
          </div>
        </Box>
      </Modal>
    </section>
  );
};

export default trackWindowScroll(Verification);
