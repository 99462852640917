/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import {
  LazyLoadImage,
  ScrollPosition,
  trackWindowScroll,
} from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { useFormik } from "formik";
import * as Yup from "yup";
import { TextField } from "@mui/material";
import useTranslation from "../../hooks/Translation";
import { generateSEKAndHash } from "../../utils/crypto";
import { usePostContactUsMutation } from "../../service/Common";
import { errorToast, successToast } from "../../helpers/ShowToast";
import { Loader } from "../../components";

type ContactType = {
  scrollPosition: ScrollPosition;
};
const Contact = ({ scrollPosition }: ContactType) => {
  const translation = useTranslation() as any;
  const [postContactUs, { isLoading }] = usePostContactUsMutation();
  // const getCmsData = useAppSelector(getCms);
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      contactUsText: "",
    },
    validationSchema: Yup.object({
      contactUsText: Yup.string()
        .required("This field is required")
        .min(3, "Minimum 3 characters are required"),
      // .max(200, "Maximum 100 characters are allowed"),
    }),
    onSubmit: async () => {
      formik.setSubmitting(true);

      const data = {
        message: formik.values.contactUsText,
        appkey: new Date().toISOString(),
        deviceType: "WEB",
      };
      console.log(data, "data");

      const body = generateSEKAndHash(data);

      if (body) {
        try {
          const response = await postContactUs(body).unwrap();
          if (response?.statusCode === 200) {
            successToast("Request submitted successfully");
          }
        } catch (error: any) {
          if (error?.data?.message) {
            errorToast(error?.data?.message || "");
          }
        }
      }
    },
  });

  const contact = [
    // {
    //   id: 2,
    //   image: "/static/images/callcntc.png",
    //   title: translation.pridictionList.callnow,
    //   description: translation.pridictionList.callp,
    //   href: getCmsData
    //     ? `tel:${getCmsData?.countryCode} ${getCmsData?.phone}`
    //     : "",
    //   target: "",
    //   rel: "",
    // },
    {
      id: 3,
      image: "/static/images/write.png",
      title: translation.pridictionList.writeus,
      description: translation.pridictionList.average,
      // href: getCmsData ? `mailto:${getCmsData?.email}` : "",
      // target: "_blank",
      // rel: "noopener noreferrer",
    },
  ];

  return (
    <div className="editprofile">
      <Loader isLoad={isLoading} />
      <h4>{translation.pridictionList.contct}</h4>
      <div className="acc_Contnr">
        <div className="contct">
          <figure className="contctfig">
            <LazyLoadImage
              src="/static/images/contact.png"
              alt=""
              effect="blur"
              height="100%"
              width="100%"
              scrollPosition={scrollPosition}
            />
          </figure>
          <h3>{translation.pridictionList.facingErr}</h3>
          <p>{translation.pridictionList.facingP}</p>

          {contact.map((item) => {
            return (
              <div className="chatwithus" key={item.id}>
                <div className="chleft">
                  <figure className="contctfig">
                    <LazyLoadImage
                      src={item?.image}
                      alt=""
                      effect="blur"
                      height="100%"
                      width="100%"
                      scrollPosition={scrollPosition}
                    />
                  </figure>
                </div>
                <div className="chRyt">
                  {/* <a href={item.href} target={item.target} rel={item.rel}> */}{" "}
                  <h3>{item?.title}</h3>
                  {/* </a> */}
                  <p style={{ textAlign: "left", lineHeight: "22px" }}>
                    {item?.description}
                  </p>
                  <form onSubmit={formik.handleSubmit}>
                    <div className=" input_query" style={{ padding: 0 }}>
                      <TextField
                        className="text_field"
                        hiddenLabel
                        type="text"
                        placeholder="Write here"
                        fullWidth
                        multiline
                        minRows={4}
                        maxRows={6}
                        name="contactUsText"
                        variant="outlined"
                        id="contactUsText"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.contactUsText}
                        helperText={
                          formik.touched.contactUsText &&
                          formik.errors.contactUsText
                        }
                      />
                      <button
                        className="btn"
                        type="submit"
                        style={{ marginTop: "30px" }}
                      >
                        Send
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default trackWindowScroll(Contact);
