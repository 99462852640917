/* eslint-disable import/no-extraneous-dependencies */
import React, { Dispatch, SetStateAction, useRef, useState } from "react";
// import { useNavigate } from "react-router-dom";
import { Tooltip } from "@mui/material";
import {
  LazyLoadImage,
  ScrollPosition,
  trackWindowScroll,
} from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import useTranslation from "../../hooks/Translation";

interface DrawerState {
  scrollPosition: ScrollPosition;
  setSidebarVisible: Dispatch<SetStateAction<boolean>>;
  tournamentId: string | "";
}

const InviteFriend = ({
  setSidebarVisible,
  tournamentId,
  scrollPosition,
}: DrawerState) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const translation = useTranslation() as any;
  const textRef = useRef<HTMLDivElement>(null);
  const [toolTipMessage, setToolTipMessage] = useState<string>(
    translation.toastMessages.copy_content
  );

  const handleCopy = (): void => {
    navigator.clipboard.writeText(
      tournamentId !== ""
        ? `https://frediction.com/tournamentdetail?id=${tournamentId}`
        : "https://frediction.com/"
    );
    setToolTipMessage(translation.toastMessages.copied);
    setTimeout(() => {
      setToolTipMessage(translation.toastMessages.copy_content);
    }, 2000);

    // if (textRef.current) {
    //   const textToCopy: string = textRef.current.innerText;
    //   copy(textToCopy)
    //     .then(() => {
    //       console.log("Text copied to clipboard:", textToCopy);
    //       // You can show a success message or perform any other actions here
    //     })
    //     .catch((error: Error) => {
    //       console.error("Failed to copy text:", error);
    //       // You can show an error message or handle the error here
    //     });
    // }
  };

  return (
    <div className="chat_otr invitemain">
      <div className="sb text_center">
        <div className="mob_view">
          <i
            className="fa-solid fa-arrow-up-long bck_btn mt_19"
            onClick={() => setSidebarVisible(false)}
          />
        </div>
        <h2>{translation.mytourney.invite}</h2>
      </div>
      <div className="invt">
        <figure>
          <LazyLoadImage
            src="/static/images/invitepic.png"
            alt=""
            effect="blur"
            scrollPosition={scrollPosition}
          />
        </figure>
        <div className="invite_frnds">
          <p className="copy_link" ref={textRef}>
            {tournamentId !== ""
              ? `https://frediction.com/tournamentdetail?id=${tournamentId}`
              : "https://frediction.com/"}
          </p>
          <Tooltip title={toolTipMessage}>
            <button onClick={handleCopy} type="button" className="copy_icn">
              <figure style={{ width: "25px", height: "25px" }}>
                <LazyLoadImage
                  src="/static/images/copy.png"
                  alt=""
                  effect="blur"
                  scrollPosition={scrollPosition}
                />
              </figure>
            </button>
          </Tooltip>
        </div>
        <p>{translation.mytourney.invitemgs}</p>
      </div>
    </div>
  );
};

export default trackWindowScroll(InviteFriend);
