/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable object-shorthand */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useState } from "react";
import { Box, Modal, TextField } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { useFormik } from "formik";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import {
  LazyLoadImage,
  ScrollPosition,
  trackWindowScroll,
} from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import useTranslation from "../../hooks/Translation";
// import { errorToast, successToast } from "../../constants";
import { usePostForgotPasswordMutation } from "../../service/Auth";
import { generateSEKAndHash } from "../../utils/crypto";
import { errorToast } from "../../helpers/ShowToast";
import { Loader } from "../../components";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 300,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 3,
  borderRadius: 3,
  outline: "none",
  border: "none",
  cursor: "pointer",
};

type props = {
  scrollPosition: ScrollPosition;
};
const ForgotPassword = ({ scrollPosition }: props) => {
  const navigate = useNavigate();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const translation = useTranslation() as any;
  const [open, setOpen] = React.useState(false);
  const [countryName, setCountryName] = useState("in");
  const [phoneCode, setPhoneCode] = useState("+971");

  const [ForgotPasswordMutation, ForgotPasswordMutationData] =
    usePostForgotPasswordMutation();

  const deviceId = navigator.userAgent;

  const handleClick = () => {
    setOpen(false);
    navigate("/login");
  };
  // formik
  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: Yup.object({
      // phone: Yup.string()
      //   .required(translation.validations.phone_required)
      //   .min(8, translation.validations.correct_phone)
      //   .max(14)
      //   .label(translation.validations.phone_no),
      email: Yup.string()
        .required(translation.validations.email_required)
        .label("Email")
        .matches(
          // eslint-disable-next-line no-useless-escape
          /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i,
          translation.validations.valid_email
        ),
    }),
    onSubmit: async () => {
      formik.setSubmitting(true);

      const data = {
        // countryCode: (phoneCode.includes("+") ? "" : "+") + phoneCode,
        // phone: formik.values.phone,
        email: formik.values.email,
        appkey: new Date().toISOString(),
        deviceId: deviceId,
      };
      const body = generateSEKAndHash(data);
      if (body) {
        try {
          const response = await ForgotPasswordMutation(body).unwrap();
          if (response?.statusCode === 200) {
            const verifyData = {
              // countryCode: phoneCode,
              // phone: formik.values.phone,
              email: formik.values.email,
              mode: "forget",
            };
            navigate("/verification", { state: verifyData });
          } else if (response?.statusCode === 503) {
            errorToast(translation.toastMessages.service_unavailable);
          }
        } catch (error: any) {
          if (error?.data?.message) {
            errorToast(error?.data?.message || "");
          } else {
            errorToast(translation.toastMessages.no_internet);
          }
        }
      }
    },
  });

  // for country picker
  const handleChangePhone = (phone: any, country: any) => {
    setPhoneCode(country?.dialCode);
    formik.setFieldValue("phone", phone?.replace(country.dialCode, ""));
    setCountryName(country?.countryCode);
  };
  return (
    <section className="signUpmain">
      <Loader isLoad={ForgotPasswordMutationData.isLoading} />
      <div className="container">
        <div className="sigup">
          <Link to="/">
            <figure>
              <LazyLoadImage
                src="/static/images/frediction.png"
                alt=""
                effect="blur"
                height="100%"
                width="100%"
                scrollPosition={scrollPosition}
              />
            </figure>
          </Link>
          <div className="close" onClick={() => navigate("/login")}>
            <figure>
              <LazyLoadImage
                src="/static/images/close-square.svg"
                alt=""
                effect="blur"
                height="100%"
                width="100%"
                scrollPosition={scrollPosition}
              />
            </figure>
          </div>
          <div className="forgotcontainer">
            <h4>{translation.auth.forgot}</h4>

            <p className="fw_20 frgt_fw">{translation.auth.forgotdes}</p>

            <form onSubmit={formik.handleSubmit}>
              <div className="inputdiv">
                {/* <div className="phoinput">
                  <PhoneInput
                    country="us"
                    excludeCountries={["bg"]}
                    value={phoneCode + formik.values.phone}
                    inputClass="phoneInput"
                    buttonClass="phoneBtn"
                    onChange={(phone, country) =>
                      handleChangePhone(phone, country)
                    }
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.phone && formik.errors.phone ? (
                    <h6 className="validation_msg2">
                      {formik.touched.phone && formik.errors.phone}
                    </h6>
                  ) : (
                    ""
                  )}
                </div> */}
                <TextField
                  placeholder={translation.pridictionList.email}
                  fullWidth
                  id="email"
                  name="email"
                  type="text"
                  className="txt_fld"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.email}
                  helperText={formik.touched.email && formik.errors.email}
                />
                <button
                  className="btn submit_btn mtm_80"
                  type="submit"
                  style={{ width: "100%" }}
                  disabled={formik.isSubmitting}

                  // onClick={() => setOpen(true)}
                >
                  {translation.Globals.continue}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <Modal
        open={open}
        // onClose={() => setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="forgot_pop"
      >
        <Box sx={style} className="forgot_wrp" onClick={handleClick}>
          <div className="linksend">
            <figure>
              <LazyLoadImage
                src="/static/images/lock.svg"
                alt=""
                effect="blur"
                height="100%"
                width="100%"
                scrollPosition={scrollPosition}
              />
            </figure>
            <h2> {translation.auth.linksent}</h2>
            <p>{translation.auth.linkText}</p>
          </div>
        </Box>
      </Modal>
    </section>
  );
};

export default trackWindowScroll(ForgotPassword);
