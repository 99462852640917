export const en = {
  Globals: {
    save: "Save",
    new_entry: "Save as New Entry",
    submit: "Submit",
    addEntry: "Add New Entry",
    great: "Ok, Great!",
    applyFilter: "Apply Filter",
    rank: "Rank",
    continue: "Continue",
    sign_up: "Sign Up",
    sign_in: "Sign In",
    privacy: "Privacy Policy",
    terms: "Terms and Conditions",
    back: "Back",
    days: "Days",
    hours: "Hours",
    regFee: " Reg Fee",
    tokens: "Tokens",
    pre_dictions: "Predictions",
    yes: "Yes",
    no: "No",
    character: "Character",
    uppercase: "Uppercase",
    numeric: "Numeric",
    updatePassword: "Update Password",
    no_data: "No data found",
    joined: "Joined",
    page_not_found: "Page Not Found",
    no_page_des:
      " The page you are looking for does not exist. How you got here is a mystery. But you can click the button below to go back to the homepage.",
    premium: "Premium",
    tknCat: "Save & Choose Category",
  },
  header: {
    my_tourneys: "My Tourneys",
    leaderboard: "Leaderboard",
    invite_friends: "Invite Friends",
    profile: "Profile",
    home: "Home",
  },
  footer: {
    privacy_policy: "Privacy Policy",
    contact_us: "Contact Us",
  },
  homepage: {
    top_seasons: "Season Jul - Aug",
    selection: "Selection of the week",
    participate:
      "Participate in Selection of the Week for the coming 5 weeks and Win!!",
    extra_bonus: "Extra Bonus",
    collect_points:
      "Collect more than 40 points in any tournaments and get up to 1000x your token entry fees",
    working: "How it works",
    registration_closed: "Registration closed",
    reg_close_in: "Registration closes in",
  },
  extraBonus: {
    extraBonus: "Extra Bonus",
    star_match: " Focus on the Star Match where it could give you 10 points",
    good_luck: "Enjoy and Good Luck!!",
    payFee:
      "You still need to pay the registration fees for any category separately. *You will find it under “My Tourneys”",
    learn_more: " Learn more & Subscribe",
  },
  predictions: {
    pred: "Your Predictions",
    select: "Please select your one match as Star Match.",
    note: "Note: You will get double points",
    predict: "Predict Games",
    premierLeague: "Premier League - Matchday 27",
    totalTokens: "Total Tokens",
  },
  mytourney: {
    tourney: "My Tourneys",
    tournamentDetail: "Tournament Detail",
    upcoming: "UPCOMING",
    history: "HISTORY",
    token: "Tokens",
    tokenpot: "Total Pot",
    tourneytype: "Tourney",
    team1: "RCD Mallorca",
    team2: "Ca Ossuna",
    matchdetail: "MATCH DETAILS",
    pridiction: "YOUR PREDICTION",
    total: "Total",
    entries: "entries",
    rank: "Rank/Name",
    status: "Status",
    newentry: "New Entry",
    invite: "Invite Friends",
    invitemgs:
      "Invite friends to Frediction and enjoy your favorite tourneys & play with them.",
    message: "Messages",
    write: "Write Something...",
    distri: "Prize Distribution",
    entry: "Entry Fee",
    totalpot: "Total POT",
    scores: "SCORES",
    cards: "Cards",
    pred: "Pred",
    pts: "Pts",
    total_pts: "Total",
  },
  entries: {
    yourentry: "Your Entries",
    lastsaved: "Last Saved",
  },
  pridictionList: {
    predlist: "Predictions List",
    balance: "Balance",
    add: "ADD",
    win: "Win",
    winRate: "Win Rate",
    editprofile: "EDIT PROFILE",
    notification: "NOTIFICATIONS",
    country: "COUNTRY & LANGUAGE",
    contct: "CONTACT US",
    about: "ABOUT APP",
    faq: "FAQ",
    logout: "LOG OUT",
    fullname: "Full Name",
    username: "Username",
    firstname: "First Name",
    lastname: "Last Name",
    email: "Email",
    changePas: "CHANGE PASSWORD",
    facingErr: "Facing any issue?",
    facingP: "Please get in touch and we will be happy to help you",
    chatwithus: "Chat with Us",
    callnow: "Call Now",
    writeus: "Write to Us",
    chatp: "For a better experience, chat from your registered number",
    callp: "For a better experience, call from your registered number",
    average:
      "One of our team will contact you over Whatsapp on your registered mobile number, average response time 1-24 Hrs",
    selectcrry: "Select your preferred Currencies",
    enteroldpass: "Enter Old Password",
    enternewpass: "Enter New Password",
    confirmpass: "Confirm Your Password",
    pass: "Password",
    deposit: "Deposit",
    withdraw: "Withdraw",
    transactions: "TRANSACTIONS",
    myprofile: "My Profile",
    sure_logout: "Are you sure you want to logout?",
    token_req: " Your Token request for ",
    approved: "has been approved and transit to your account ",
  },
  tourneyCreated: {
    against: "against",
    players: "players",
    subscribed: "Subscribed",
    reg_fee: "   Registration fee",
    live: "Live",
    pot: "Total Pot",
    total_entries: "total entries",
    public: "Public T B against 7 players",
    created: "Created",
    matchStart: "The first match starts in ",
    days: "days",
    note1: 'Note: You can edit your scores from the "My Tourneys" page until',
    note2: "30 mins",
    note3: " before the start of the match.",
    days_to_start: "days to start",
  },
  leaderBoard: {
    leaderBoard: "Leaderboard",
    token_history: "Token History",
    filters: "Filters",
    tounrney_dt: "Tourney & Date",
    position: "Position",
    cate_gory: "Cat",
    points: "Points",
    your_rank: "Your Rank",
    players: "Players",
    teams: "Teams",
    tokens_received: "Token Received",
    filter: "Filter by",
    clear: "Clear all",
    category: "Category",
    tourney: "Tourney",
    gold: "Gold (100 Token)",
    silver: "Silver (10 Token)",
    platinum: "Platinum (1 Token)",
    selection: "Selection Of The Week",
    date: "Date",
    rank1: "#Rank 1",
    rank2: "#Rank 2",
    rank3: "#Rank 3",
    total: "Total",
    date_from: "Date From",
    date_to: "Date To",
  },
  auth: {
    signup: "Sign Up",
    signin: "Sign In",
    info_visible:
      "Please be aware below information will be visible to other players*",
    info_confidential:
      "Please note below information will stay confidential and to be used by admin only to contact you*",
    select: "Select your Favorite Team",
    haveacc: "Already have an account?",
    noacc: "Don't have an account?",
    emailphone: "Email address or phone number",
    login: "Login",

    remember: "Remember me",
    forgot: "Forgot Password?",
    forgotdes: "Please enter your email to continue",
    forgotdes2: "Please enter your phone number to continue",
    linksent: "Link Sent",
    linkText:
      "We have sent the link to reset your password on Simranahitaan@apptunix.com",
    verification: "Verification",
    verify_des: "We have just sent you a verification code on ",

    code_expire: "The verify code will expire in",
    resend: "Resend Code",
    congrats: "Congratulations",
    profile_done: "Profile setup done Successfully",
    reset_password: "Reset Password",
    new_pass: "New Password",
    confirmpass: "Confirm Password",
  },
  seasonal_tournamnet: {
    pay: "Pay",
    subscribe: "Tokens & Subscribe",
    subscribed: "Subscribed Successfully",
  },
  validations: {
    phone_required: "Phone number is required.",
    correct_phone: "Please enter correct phone number",
    phone_no: "Phone number",
    user_required: "User name is required.",
    min_username: "User name must be at least 2 characters.",
    firstname_req: "First name is required.",
    min_firstname: "First name must be at least 2 characters.",
    min_lastname: "Last name must be at least 2 characters.",
    email_required: "Email is required.",
    valid_email: "Must be a valid email.",
    password_required: "Password is required.",
    pass_valid:
      "Must contain 8 or more characters, one uppercase, one lowercase and one number.",
    confirm_pass: "Passwords must match.",
    confirmpass_req: "Confirm password is required.",
    required_field: "This field is compulsory.",
    old_pass_req: "Old password is required",
    new_pass_req: "New password is required",
    new_pass_val: "New password cannot be same as old password.",
  },
  toastMessages: {
    service_unavailable: "Please wait! Service not available",
    images_allowed: "Only images are allowed",
    no_internet: "No internet connection",
    phone_changed: "Phone number changed successfully",
    copy_content: "Copy the content.",
    copied: "Copied to clipboard.",
    verify_new_phone: "Please verify new phone number",
    tournament_started: "Tournament has started so, you can't add predictions",
    select_tokencat: "Please select token categories",
  },
  profile: {
    deposit: "Deposit",
    thankYou: "Thank you for showing interest in depositing,",
    thankYouWithdraw: "Thank you for showing interest in withdrawing,",
    contactYou:
      "We will contact you shortly over WhatsApp on this mobile number",
    whatsAppNo:
      "Please make sure you have WhatsApp on this number, or edit it if this number has changed",
    withdraw: "Withdraw",
    request_received: "Your withdrawal request has been received,",
  },
};
