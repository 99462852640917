/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import "./App.scss";
import { toast } from "react-toastify";
import LanguageContextProvider from "./context/LanguageContext";
import Router from "./Routes";
import { useLazyGetProfileQuery } from "./service/Auth";

import { useAppDispatch } from "./hooks/Store";
import { setCredentials } from "./reducers/authSlice";
import { STORAGE_KEYS, getFromStorage } from "./helpers/Storage";

import { useLazyGetCmsQuery } from "./service/cms";
import { resetCms } from "./reducers/generalSlice";
import { firebaseCloudMessaging } from "./utils/firebase";

const App = () => {
  const dispatch = useAppDispatch();
  const [GetProfile] = useLazyGetProfileQuery();
  const token = getFromStorage(STORAGE_KEYS.token);
  const [GetCms] = useLazyGetCmsQuery();

  console.log = () => {};

  const fetchCmsData = async () => {
    try {
      const res = await GetCms({}).unwrap();

      if (res?.statusCode === 200) {
        dispatch(resetCms(res?.data));
      }
    } catch (error: any) {
      console.log(error, "error=========>");
    }
  };

  useEffect(() => {
    fetchCmsData();
  }, []);

  useEffect(() => {
    // Calls the getMessage() function if the token is there
    async function setToken() {
      try {
        const fcmToken = await firebaseCloudMessaging.init();
        if (fcmToken) {
          console.log("fcm_Token", fcmToken);
        }
      } catch (error) {
        console.log("Error during FCM initialization:", error);
      }
    }

    setToken();
    // Event listener that listens for the push notification event in the background
    if ("serviceWorker" in navigator) {
      navigator.serviceWorker.addEventListener("message", (event) => {
        try {
          event?.source?.postMessage("Hi client");
          console.log("event for the service worker", event);
          return toast(
            <div style={{ height: "100%" }}>
              <div>{event?.data?.notification?.title}</div>
              <div>{event?.data?.notification?.body}</div>
            </div>
          );
          return event;
        } catch (e) {
          console.log(e, "error");
          return e;
        }
      });
    }

    return () => {
      if ("serviceWorker" in navigator) {
        navigator.serviceWorker.removeEventListener("message", (e) =>
          console.log(e)
        );
      }
    };
  }, []);

  const fetchData = async () => {
    try {
      const response = await GetProfile({}).unwrap();
      if (response?.statusCode === 200) {
        dispatch(
          setCredentials({
            token: JSON.parse(`${token}`),
            user: response?.data || null,
          })
        );
      }
    } catch (error: any) {
      console.log(error, "error=========>");
    }
  };

  useEffect(() => {
    if (token) {
      fetchData();
    }
  }, []);
  return (
    <LanguageContextProvider>
      <Router />
    </LanguageContextProvider>
  );
};

export default App;
