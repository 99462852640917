/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-sequences */
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Box, Modal } from "@mui/material";
// eslint-disable-next-line import/no-extraneous-dependencies
import moment from "moment";
import {
  LazyLoadImage,
  ScrollPosition,
  trackWindowScroll,
} from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import Layout from "../../layout";
import "./Predictions.scss";
import useTranslation from "../../hooks/Translation";
import { EditPredictionCard, Loader, PredictionCard } from "../../components";
import { PredictionDrawer } from "../../features";
import {
  useEditPredictionMutation,
  useLazyGetPredictionByUserNameQuery,
  useLazyGetTournamentFixturesQuery,
  usePostSavePredictionMutation,
} from "../../service/tournaments";
import { errorToast, successToast, warnToast } from "../../helpers/ShowToast";
import { useAppDispatch, useAppSelector } from "../../hooks/Store";
import {
  getTokenCategories,
  resetTokenCategories,
} from "../../reducers/tournamentSlice";
import { Tournaments, preDictionByUser } from "../../types/general";
import { getFromStorage, STORAGE_KEYS } from "../../helpers/Storage";
import { getToken } from "../../reducers/authSlice";
import { generateSEKAndHash } from "../../utils/crypto";

interface GroupedData {
  [date: string]: Tournaments[];
}
interface GroupedData2 {
  [date: string]: preDictionByUser[];
}

type props = {
  scrollPosition: ScrollPosition;
};
const Predictions = ({ scrollPosition }: props) => {
  const translation = useTranslation() as any;
  const navigate = useNavigate();
  const { _id } = useParams();
  const location = useLocation();
  const { state } = location;
  console.log(state, "state");

  const dispatch = useAppDispatch();
  const token = useAppSelector(getToken);
  const tokenCategories = useAppSelector(getTokenCategories);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [open, setOpen] = useState<boolean>(false);
  const [matches, setMatches] = useState<GroupedData | null>(null);
  const [matches1, setMatches1] = useState<GroupedData2 | null>(null);
  const [selectedMatch, setSelectedMatch] = useState<string>("");
  const [sidebarVisible, setSidebarVisible] = React.useState(false);
  const [tournamentFixtures, setTournamentFixtures] = useState<Tournaments[]>(
    []
  );
  const [totalToken, setTotalToken] = useState(0);
  const [tokenCategoriesArr, setTokenCategoriesArr] = useState<string[]>([]);
  const [predictions, setPredictions] = useState<preDictionByUser[]>([]);
  const [predictionByUserName] = useLazyGetPredictionByUserNameQuery();
  const [getTournamentFixtures] = useLazyGetTournamentFixturesQuery();
  const [savePredictionMutation] = usePostSavePredictionMutation();
  const [editPredictionMutation] = useEditPredictionMutation();
  const [saveType, setSaveType] = useState("");
  const [loading, setLoading] = useState<boolean>(false);
  const [firstDate, setFirstDate] = useState<string>();

  const [registrationtTimer, setRegistrationTimer] = useState<string>("");
  // const [isLiveMatch, setIsLiveMatch] = useState(false);

  const handleClose = () => setOpen(false);
  const totalPot = predictions[0]?.tokenCategory?.tokens;
  console.log(state, "statep#####l");
  useEffect(() => {
    if (state?.tournamentFixtures?.length) {
      const copiedFixturesss = state?.tournamentFixtures
        ? [...state?.tournamentFixtures]
        : [];
      const sort = copiedFixturesss?.sort((a, b) => {
        const dateA = moment(a?.fixture?.date) as any;
        const dateB = moment(b?.fixture?.date) as any;
        return dateA - dateB;
      });
      const fDate = sort?.length ? sort[0]?.fixture.date : undefined;

      if (fDate) {
        setFirstDate(fDate);
      }
    }
  }, [state?.tournamentFixtures]);

  useEffect(() => {
    let timer: NodeJS.Timeout;

    if (firstDate) {
      const updateTimeLeft = () => {
        const currentDateTime = moment(); // Current date and time
        const targetDateTime = moment(firstDate); // Target date and time
        const timeDiff = targetDateTime.diff(currentDateTime); // Difference in milliseconds

        if (timeDiff) {
          const duration = moment.duration(timeDiff);

          const days = String(duration.days()).padStart(2, "0");
          const hours = String(duration.hours()).padStart(2, "0");
          const minutes = String(duration.minutes()).padStart(2, "0");
          const seconds = String(duration.seconds()).padStart(2, "0");

          const remainingDaysNumber = parseInt(days, 10);

          if (remainingDaysNumber > 1) {
            setRegistrationTimer(`${days} ${translation.tourneyCreated.days} `);
          } else {
            const totalMinutes =
              parseInt(hours, 10) * 60 + parseInt(minutes, 10);
            const cutoffTime = parseInt(state?.cutoff, 10);
            const remainingMinutes = totalMinutes - cutoffTime;
            const remainingHours = Math.floor(remainingMinutes / 60);
            const remainingMinutesInHour = remainingMinutes % 60;

            if (remainingHours < 0 && remainingMinutesInHour < 0) {
              setRegistrationTimer("");
            } else {
              setRegistrationTimer(
                `${remainingHours}h: ${String(remainingMinutesInHour).padStart(
                  2,
                  "0"
                )}m: ${seconds}s`
              );
            }
          }
        } else {
          clearInterval(timer);
        }
      };

      timer = setInterval(updateTimeLeft, 1000);
      updateTimeLeft();
    } else {
      // console.log("No fixtures found.");
    }

    return () => {
      clearInterval(timer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firstDate]);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 300,
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: 2,
    outline: "none",
    border: "none",
  };

  useEffect(() => {
    const groupedData: GroupedData = tournamentFixtures?.reduce(
      (groups: GroupedData, obj: Tournaments) => {
        const { fixture } = obj;

        const date = fixture?.date?.substring(0, 10); // Extracting only the date part

        if (!groups[date]) {
          groups[date] = [];
        }
        groups[date].push(obj);
        return groups;
      },
      {}
    );
    setMatches(groupedData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tournamentFixtures]);

  useEffect(() => {
    const result = predictions?.filter((prediction) => prediction?.isFavourite);

    if (result.length && !selectedMatch?.length) {
      setSelectedMatch(result[0]?._id);
      // setTokenCategoriesArr(result[0]?.tokenCategory);
    }

    const groupedData2: GroupedData2 =
      predictions &&
      predictions?.reduce((groups: GroupedData2, obj: preDictionByUser) => {
        const newData = obj?.fixtureId?.fixture;

        const date = newData?.date?.substring(0, 10); // Extracting only the date part
        if (!groups[date]) {
          groups[date] = [];
        }
        groups[date].push(obj);
        return groups;
      }, {});
    setMatches1(groupedData2);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [predictions]);

  const extractCategories = async () => {
    const result = getFromStorage(STORAGE_KEYS.tokenCategories) as string;
    const newCategories = await JSON.parse(result);
    dispatch(resetTokenCategories(newCategories));
  };

  useEffect(() => {
    if (!tournamentFixtures?.length) {
      extractCategories();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClick = (item: any) => {
    if (state === "livematch") {
      /* empty */
    } else {
      setSelectedMatch(item?._id);
    }
  };
  const handleSave = () => {
    if (!token) {
      navigate("/login");
      return;
    }
    if (state?.tournamentLive) {
      warnToast(translation.toastMessages.tournament_started);
      return;
    }
    setSaveType("add");
    if (!selectedMatch?.length) {
      setOpen(true);
    } else {
      setSidebarVisible(true);
    }
  };

  const handleSave1 = () => {
    if (state?.isLive) {
      warnToast(translation.toastMessages.tournament_started);
      return;
    }
    setSaveType("edit");
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    editPrediction();
  };

  const handleSaveNewEntry = async () => {
    if (state?.isLive) {
      warnToast(translation.toastMessages.tournament_started);
      return;
    }
    setSaveType("save");
    if (!selectedMatch?.length) {
      setOpen(true);
    } else {
      setSidebarVisible(true);
    }
  };

  const fetchData = async () => {
    try {
      setLoading(true);
      const res = await getTournamentFixtures({
        tournament_id: _id,
      }).unwrap();
      setLoading(false);
      if (res?.statusCode === 200) {
        setTournamentFixtures(res?.data);
      } else if (res.statusCode === 503) {
        errorToast(translation.toastMessages.service_unavailable);
      }
    } catch (error: any) {
      setLoading(false);
    }
  };
  const getPredictionsByUsername = async () => {
    try {
      setLoading(true);
      const response = await predictionByUserName({
        id: state?.id,
        userName: state?.userName,
        categoryId: state?.categoryId,
      }).unwrap();
      setLoading(false);
      if (response?.statusCode === 200) {
        setPredictions(response?.data);
      }
    } catch (error: any) {
      setLoading(false);
      console.log(error);
    }
  };

  const handleSavePrediction = async () => {
    let result = [] as any[];
    if (saveType === "add") {
      result = tournamentFixtures?.map((item) => {
        const obj = {
          tournament_id: _id,
          fixture_id: item?.fixture_id,
          homeTeam: item?.teams?.home?.name,
          awayTeam: item?.teams?.away?.name,
          homeTeam_id: item?.teams?.home?.id,
          awayTeam_id: item?.teams?.away?.id,
          homeTeam_goals: item?.goals?.home || 0,
          awayTeam_goals: item?.goals?.away || 0,
          tokenCategory: tokenCategoriesArr,
          homeTeam_logo: item?.teams?.home?.logo,
          awayTeam_logo: item?.teams?.away?.logo,
          fixtureId: item?._id || null,
        };
        console.log(obj, "savepredictionobj");

        if (selectedMatch === item?._id) {
          Object.assign(obj, { isFavourite: true });
        }

        return obj;
      });
    } else {
      result = predictions?.map((item) => {
        const obj = {
          tournament_id: item?.tournament_id,
          fixture_id: item?.fixture_id,
          homeTeam: item?.homeTeam,
          awayTeam: item?.awayTeam,
          homeTeam_id: item?.homeTeam_id,
          awayTeam_id: item?.awayTeam_id,
          homeTeam_goals: item?.homeTeam_goals,
          awayTeam_goals: item?.awayTeam_goals,
          tokenCategory: tokenCategoriesArr,
          homeTeam_logo: item?.homeTeam_logo,
          awayTeam_logo: item?.awayTeam_logo,
          fixtureId: item?.fixtureId?._id || null,
        };
        console.log(obj, "Savenewobj");

        if (selectedMatch === item?._id) {
          Object.assign(obj, { isFavourite: true });
        }

        return obj;
      });
    }

    const data: any = {
      predictions: result,
      appkey: new Date().toISOString(),
    };
    const body = generateSEKAndHash(data);
    if (body) {
      try {
        setLoading(true);
        const response = await savePredictionMutation(body).unwrap();
        setLoading(false);
        if (response?.statusCode === 200 || response?.statusCode === 201) {
          successToast(response?.message || "");
          navigate("/tourneycreated", {
            state: { response, totalToken, tokenCategoriesArr },
          });
        }
      } catch (error: any) {
        setLoading(false);
        errorToast(error?.data?.message || "");
      }
    }
  };

  const editPrediction = async () => {
    const result = predictions?.map((item) => {
      const obj = {
        _id: item?._id,
        tournament_id: item?.tournament_id,
        fixture_id: item?.fixture_id,
        homeTeam: item?.homeTeam,
        awayTeam: item?.awayTeam,
        homeTeam_id: item?.homeTeam_id,
        awayTeam_id: item?.awayTeam_id,
        homeTeam_goals: item?.homeTeam_goals,
        awayTeam_goals: item?.awayTeam_goals,
        tokenCategory: item?.tokenCategory?._id,
        homeTeam_logo: item?.homeTeam_logo,
        awayTeam_logo: item?.awayTeam_logo,
        fixtureId: item?.fixtureId?._id || null,
        userName: item?.userName || null,
        isFavourite: selectedMatch === item?._id,
      };
      console.log(obj, "editpredictionobj");

      return obj;
    });

    const data: any = {
      predictions: result,
      appkey: new Date().toISOString(),
    };

    const body = generateSEKAndHash(data);
    if (body) {
      try {
        setLoading(true);
        const response = await editPredictionMutation(body).unwrap();
        setLoading(false);
        if (response?.statusCode === 200 || response?.statusCode === 201) {
          successToast(response?.message || "");
          navigate("/tourneycreated", {
            state: { response, totalPot, tokenCategoriesArr },
          });
        }
      } catch (error: any) {
        setLoading(false);
        errorToast(error?.data?.message || "");
      }
    }
  };
  const handleSumbit = () => {
    if (tokenCategoriesArr.length) {
      if (saveType === "edit") {
        editPrediction();
      } else {
        handleSavePrediction();
      }
    } else {
      errorToast(translation.toastMessages.select_tokencat);
    }
  };
  useEffect(() => {
    if (state?.key === "entries") {
      getPredictionsByUsername();
    } else {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const changeGoalValue = (value: number, team: string, matchId: string) => {
    let idx = -1;
    idx = tournamentFixtures?.findIndex((ele) => ele._id === matchId);
    if (idx > -1) {
      const newTournaments = [...tournamentFixtures];
      newTournaments[idx] = {
        ...newTournaments[idx],
        goals: {
          home:
            team === "home" ? value : tournamentFixtures[idx]?.goals?.home || 0,
          away:
            team === "away" ? value : tournamentFixtures[idx]?.goals?.away || 0,
        },
      };
      setTournamentFixtures([...newTournaments]);
    }
  };

  const changeGoalValue2 = (value: number, team: string, matchId: string) => {
    let idx = -1;
    idx = predictions?.findIndex((ele) => ele._id === matchId);

    if (idx > -1) {
      const newPredictions = [...predictions];
      newPredictions[idx] = {
        ...newPredictions[idx],
        homeTeam_goals:
          team === "home"
            ? `${value}`
            : `${newPredictions[idx]?.homeTeam_goals}` || "0",
        awayTeam_goals:
          team === "away"
            ? `${value}`
            : `${newPredictions[idx]?.awayTeam_goals}` || "0",
      };
      setPredictions([...newPredictions]);
    }
  };

  return (
    <Layout>
      <Loader isLoad={loading} />
      <div className="nv_spc cmn_bg pridct_sc cms">
        <div className="container">
          <div className="pridct_tp">
            <div className="sb">
              <div className="mob_view">
                <i
                  className="fa-solid fa-arrow-up-long bck_btn"
                  onClick={() => navigate("/")}
                />
              </div>
              <h2>{translation.predictions.pred}</h2>
            </div>

            <div className="btn_sec">
              {state?.key === "entries" &&
              !state?.isLive &&
              registrationtTimer !== "" ? (
                <button
                  className="btn sv_btn"
                  type="button"
                  onClick={handleSaveNewEntry}
                  disabled={state?.isLive}
                >
                  {translation.Globals.new_entry}
                </button>
              ) : null}

              {state?.show_btn === "show_btn" ? (
                <button
                  className="btn sv_btn"
                  type="button"
                  onClick={state?.key === "entries" ? handleSave1 : handleSave}
                  disabled={state?.isLive || state?.tournamentLive}
                >
                  {translation.Globals.tknCat}
                </button>
              ) : state?.isLive ||
                // state?.tournamentLive ||
                registrationtTimer === "" ? null : state?.key === "entries" &&
                !state?.isLive &&
                registrationtTimer !== "" ? (
                <button
                  className="btn sv_btn"
                  type="button"
                  onClick={handleSave1}
                  disabled={state?.isLive}
                >
                  {translation.Globals.save}
                </button>
              ) : (
                <button
                  className="btn sv_btn"
                  type="button"
                  onClick={state?.key === "entries" ? handleSave1 : handleSave}
                  disabled={state?.isLive || state?.tournamentLive}
                >
                  {translation.Globals.tknCat}
                </button>
              )}
            </div>
          </div>
          <div className="detail_sec">
            <div
              className="info-pre"
              onClick={() =>
                navigate(`/tournamentdetail?id=${_id}`, {
                  state: state?.tournamentId,
                })
              }
            >
              <h6 className="detail_text">Tournament Detail</h6>
              <ErrorOutlineIcon />
            </div>
            <div
              className="info-pre"
              onClick={() =>
                navigate("/pointscalculation", { state: state?.tournamentId })
              }
            >
              <h6 className="detail_text">Points Calculation</h6>
              <ErrorOutlineIcon />
            </div>
          </div>
          <div className="btm_prdctn">
            <div className="prit_crd_otr">
              {matches &&
                Object.keys(matches).map((date) => (
                  <div key={date}>
                    <p
                      style={{
                        color: "#fff",
                        fontSize: "12px",
                        textAlign: "center",
                        padding: "0px 0 10px",
                      }}
                    >
                      {moment(date).format("dddd MMMM D")}
                    </p>
                    {matches[date].map((item) => (
                      <div className="prit_crd_mn">
                        <PredictionCard
                          handleClick={() => handleClick(item)}
                          select_id={selectedMatch}
                          state={state}
                          item={item}
                          changeGoalValue={changeGoalValue}
                        />
                      </div>
                    ))}
                  </div>
                ))}
            </div>
            <div className="prit_crd_otr">
              {matches1 &&
                Object.keys(matches1).map((date) => (
                  <div key={date}>
                    <p
                      style={{
                        color: "#fff",
                        fontSize: "12px",
                        textAlign: "center",
                        padding: "0px 0 10px",
                      }}
                    >
                      {moment(date).format("dddd MMMM D")}
                    </p>
                    {matches1[date].map((item) => (
                      <div className="prit_crd_mn">
                        <EditPredictionCard
                          handleClick={() => handleClick(item)}
                          select_id={selectedMatch}
                          state={state}
                          item={item}
                          changeGoalValue={changeGoalValue2}
                        />
                      </div>
                    ))}
                  </div>
                ))}
            </div>
            <div className="mbl_btn">
              {state?.key === "entries" &&
              !state?.isLive &&
              registrationtTimer !== "" ? (
                <button
                  className="btn sv_btn"
                  type="button"
                  onClick={handleSaveNewEntry}
                  disabled={state?.isLive}
                >
                  {translation.Globals.new_entry}
                </button>
              ) : null}
              {state === "livematch" ? (
                <button
                  className="btn sv_btn"
                  type="button"
                  onClick={() => navigate("/mytourneys")}
                >
                  {translation.Globals.back}
                </button>
              ) : (!state?.isLive && registrationtTimer !== "") ||
                state?.show_btn === "show_btn" ? (
                <button
                  className="btn sv_btn"
                  type="button"
                  onClick={state?.key === "entries" ? handleSave1 : handleSave}
                  disabled={state?.isLive || state?.tournamentLive}
                >
                  {translation.Globals.save}
                </button>
              ) : null}
            </div>
          </div>
        </div>
      </div>
      {/* modal */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box onClick={handleClose} sx={style} className="info_modl">
          <figure>
            <LazyLoadImage
              src="/static/images/info-circle.svg"
              alt=""
              effect="blur"
              height="100%"
              width="100%"
              scrollPosition={scrollPosition}
            />
          </figure>
          <p>{translation.predictions.select}</p>
          <p className="gold_txt">
            <i>{translation.predictions.note}</i>
          </p>
        </Box>
      </Modal>
      <PredictionDrawer
        handleSubmit={handleSumbit}
        tokenCategoriesArr={tokenCategoriesArr}
        setTokenCategoriesArr={setTokenCategoriesArr}
        sidebarVisible={sidebarVisible}
        setSidebarVisible={setSidebarVisible}
        tokenCategories={tokenCategories || []}
        tournamentFixtures={tournamentFixtures}
        setTotalToken={setTotalToken}
        totalToken={totalToken}
        tournamentName={state?.tournamentName}
        tournamentImage={state?.tournamentImage}
        saveType={saveType}
      />
    </Layout>
  );
};

export default trackWindowScroll(Predictions);
